import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import _ from 'lodash';

// Import middlewares
import api from './other/middlewares/api';

// Import actions
import { setLang } from './other/actions/lang';
import { setGeneralText } from './other/actions/general-text';
import { setCurrentUser } from './other/actions/auth';

// Import helpers
import { pageIdBySlug, slugByPageId } from './other/helpers/route-finder';

// Import components
import Header from './components/header/header';
import Navigation from './components/navigation/navigation';
import Consent from './components/consent/consent';
import Popup from './components/popup/popup';
import Resolution from './components/resolution/resolution';

import './main-layout.scss';

interface props {
	'lang': string
}

function MainLayout(props: props) {
	const dispatch = useDispatch();
	const location = useLocation();
	const navigate = useNavigate();

	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: any) => state.generalText);

	const initNotifications: {[key: string]: any} = {
		'title': '',
		'text': []
	}
	const initPopups: {[key: string]: any} = {
		'showGlobalPopup': false
	}

	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	if (lang !== props.lang) {
		dispatch(setLang(props.lang));
	}

	useEffect(() => {
		api.get('/get-page/general').then((res) => {
			dispatch(setGeneralText({'generalText': res.data.text[props.lang]}));
		});

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!auth.isAuthenticated) {
			document.body.classList.toggle('dark-theme',  (generalText?.isDarkThemeEnabled ? true : false));
		} else {
			document.body.classList.toggle('dark-theme', auth.isDarkThemeEnabled);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[auth.isDarkThemeEnabled, auth.isAuthenticated, generalText?.isDarkThemeEnabled]);

	useEffect(() => {
		// if (
		// 	auth.isAuthenticated && 
		// 	!auth.showGuide &&
		// 	generalText !== undefined && 
		// 	generalText.globalPopup?.active && 
		// 	generalText.globalPopup?.popupId !== auth.globalPopupId &&
		// 	location.pathname !== slugByPageId('email-confirmation', lang, routes)
		// ) {
		// 	setTimeout(() => {

		// 		setPopups({...initPopups, 'showGlobalPopup': true});

		// 	}, generalText.globalPopup?.timeout);
		// }
		if (
			auth.isAuthenticated && 
			auth.mainProfile?.status !== 1 && 
			generalText !== undefined && 
			generalText.globalPopup?.active && 
			generalText.globalPopup?.popupId !== auth.globalPopupId &&
			location.pathname === slugByPageId('user-profiles', lang, routes)
		) {
			setTimeout(() => {

				setPopups({...initPopups, 'showGlobalPopup': true});

			}, generalText.globalPopup?.timeout);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalText]);

	const onDismissGlobalPopup = (pageId?: string) => {
		let stateClone: any = {
			'globalPopupId': generalText.globalPopup?.popupId
		}

		api.post('/edit-user-details', stateClone).then((res) => {
			dispatch(setCurrentUser({...auth, 'globalPopupId': stateClone.globalPopupId}));

			setPopups(initPopups);

			if (pageId) {
				return navigate(slugByPageId(pageId, lang, routes) + '' + generalText.globalPopup?.queryParams, {'replace': false})
			}

		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
		});
	}
	
	if (!generalText || _.isEmpty(generalText)) { return null; }

	return (
		<div className={'MAINLAYOUT' + 
			(auth.isAuthenticated ? ' diff1' : '') + 
			(' page-' + pageIdBySlug(location.pathname, lang, routes))
		}>
			<Popup
				image={generalText.globalPopup?.image}
				title={generalText.globalPopup?.title}
				position={generalText.globalPopup?.position || ''}
				submitBtn={generalText.globalPopup?.button1?.text}
				closeBtn={generalText.globalPopup?.button2?.text}
				active={popups.showGlobalPopup}
				onSubmit={() => onDismissGlobalPopup(generalText.globalPopup?.button1?.pageId)}
				onClose={() => onDismissGlobalPopup()}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12" dangerouslySetInnerHTML={{ __html: generalText.globalPopup?.text }}></div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #346324623523']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Header />
			<Navigation />
			<Consent />

			<div className="PAGE">
				<Outlet />
			</div>

			<Resolution />
		</div>
	);
}

export default MainLayout;
