// Import types
import { SET_CURRENT_USER } from './types';

export const setCurrentUser = (data: {[key: string]: any}) => {
	let newData: any = {
		'type': SET_CURRENT_USER,
		'isAuthenticated': data.id ? true : false,
		'toggleUpdate': data.toggleUpdate,
		'id': data.id,
		'email': data.email,
		'firstName': data.firstName,
		'lastName': data.lastName,
		'isEmailConfirmed': data.isEmailConfirmed,
		'credits': data.credits,
		'isDarkThemeEnabled': data.isDarkThemeEnabled,
		'creditsUpdatedAt': data.creditsUpdatedAt,
		'availableNetworks': data.availableNetworks,
		'availableProfiles': data.availableProfiles,
		'profilesCount': data.profilesCount,
		'mainProfile': data.mainProfile,
		'invitationCodeShareable': data.invitationCodeShareable,
		'isEmailPromotionsAllowed': data.isEmailPromotionsAllowed,
		'isEmailAppUpdatesAllowed': data.isEmailAppUpdatesAllowed,
		'isEmailMessageNotificationsAllowed': data.isEmailMessageNotificationsAllowed,
		'showGuide': data.showGuide,
		'newMessages': data.newMessages,
		'newLogs': data.newLogs,
		'showReferralsPopup': data.showReferralsPopup,
		'globalPopupId': data.globalPopupId,
		'unlockedContentEndsAt': data.unlockedContentEndsAt
	}

	if (data.adminType) {
		newData['adminType'] = data.adminType;
	}

	return newData;
}