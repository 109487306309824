import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Popup from './../popup/popup';

interface props {
	'title': string,
	'text': Array<any>,
	'nextButton'?: {[key: string]: any}
};

const Guide = (props: props) => {
	const dispatch = useDispatch();
	
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initPopups: {[key: string]: any} = {
		'setShowGuidePopup': false
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		// Check if user is newly registered and show guide
		if (auth.showGuide) {
			setPopups(prevState => ({...initPopups, 'showGuidePopup': true}));
		}
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onGuideDismiss = () => {
		api.put('/toggle-guide').then((res) => {
			setPopups(initPopups);
			setNotifications(res.data?.messages);
			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	if (
		!auth.isEmailConfirmed || 
		auth.profilesCount === 0 ||
		auth.mainProfile?.status !== 1 ||
		(!auth.unlockedContentEndsAt || moment(auth.unlockedContentEndsAt).isBefore(moment()))
	) {
		return null;
	}

	return (
		<>
			<Popup
				title={props.title}
				onClose={() => setPopups(initPopups)}
				active={popups.showGuidePopup}
				position="bottom"
				transparency={1}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								{
									_.map(props.text, (val1, i1) => {
										return (
											<p key={i1}>{val1}</p>
										)
									})
								}
							</div>
						</div>
					</div>
				</div>

				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-4">
								<button onClick={() => onGuideDismiss()} className="BUTTON diff1">Dismiss</button>
							</div>

							{
								props.nextButton &&
								<div className="col-8">
									<Link to={props.nextButton?.hasMainProfileId ? slugByPageId(props.nextButton?.link, lang, routes).replace(':id', auth.mainProfile?.id) : slugByPageId(props.nextButton?.link, lang, routes)} className="BUTTON">Next</Link>
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #43634234234']}
				active={!_.isEmpty(notifications?.text)}
				closeBtn={generalText?.generalPopup?.button1?.text}
				onClose={() => setNotifications(initNotifications)}
			/>
		</>
	);
}

export default Guide;
