import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Scrollbar, Autoplay } from 'swiper/modules';
import { Controller, Scene } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
import ProgressiveImg from './../../components/progressive-img/progressive-img';
import Footer from './../../components/footer/footer';

// Import styles
import './about.scss';

interface props {
	'pageId': string
}

const About = (props: props) => {
	const navigate = useNavigate();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [faqItemExpandedId, setFaqItemExpandedId] = useState<number>(0);
	const [testimonials, setTestimonials] = useState<Array<any>>([]);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			onGetTestimonials();
			
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);
	
	const onGetTestimonials = () => {
		setIsLoading(true);

		// Get testimonials
		api.get('/get-testimonials').then((res) => {
			setIsLoading(false);
			setTestimonials(res.data);

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	};

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="ABOUT">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>

			<Loader active={isLoading} fixed={true} />

			<Popup
				title={notifications?.title || 'Something went wrong'}
				closeBtn={generalText?.generalPopup?.button1?.text}
				text={notifications?.text || ['Unhandled error - #746574563333']}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>

				{
					text.intro &&
					<div className="SECTION ABOUT__intro">
						<div className="SECTION__bg diff1">
							<div className="SECTION__bg1" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg2.svg)' }}></div>
							<div className="SECTION__bg2" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg1.svg)' }}></div>
						</div>
						
						<div className="grid">
							<div className="row">
								<div className="col-12">
									<div className="grid">
										<div className="row">
											<div className="col-12 col-sm-8 push-sm-2">
												{
													text.intro?.preTitle &&
													<div className="SECTION__pretitle text-center">
														<span>{ text.intro?.preTitle }</span>
													</div>
												}

												{
													text.intro?.title &&
													<h1 className="SECTION__title font-xxxxl text-center" dangerouslySetInnerHTML={{ __html: text.intro?.title }}></h1>
												}

												{
													text.intro?.subtitle &&
													<h2 className="SECTION__subtitle text-center" dangerouslySetInnerHTML={{ __html: text.intro?.subtitle }}></h2>
												}

												{
													text.intro?.buttons && !auth.isAuthenticated &&
													<div className="SECTION__item">
														<div className="row">
															{
																_.map(text.intro?.buttons, (val1, i1) => {
																	return (
																		<div key={i1} className={val1.gridClasses}>
																			<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																		</div>
																	)
																})	
															}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{
					_.map(text.problems, (val1, i1: any) => {
						return (
							<div key={i1} className={'SECTION padding-wrap-xl text-left' + (i1 === 0 ? ' has-top-border' : '') + (parseInt(text.problems.length) - 1 !== i1 ? ' no-bottom-padding' : '')}>
								<div className="grid">
									<div className={'row vertical-aligned' + (val1.position === 'right' ? ' reversed-cols' : '')}>
										{
											val1.image1 &&
											<div className={'col-12' + (val1.position === 'right' ? ' push-sm-1' : '') + (val1.position !== 'center' ? ' col-sm-6' : '')}>
												<Scene>
													{(progress: any, event: any) => (
														<div className="ABOUT__problems-image">
															<ProgressiveImg
																img={false}
																showLoader={true}
																shouldLoad={event.type === 'start'}
																lowQualitySrc=""
																highQualitySrc={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' +  val1.image1}
															/>
														</div>
													)}
												</Scene>
											</div>
										}
										
										<div className={'col-12' + (val1.position === 'left' ? ' push-sm-1' : '') + (val1.position !== 'center' ? ' col-sm-5' : '')}>
											{
												val1.preTitle &&
												<div className={'SECTION__pretitle' + (val1.position === 'center' ? ' text-center' : ' text-left')}>
													<span>{ val1.preTitle }</span>
												</div>
											}

											{
												val1.title &&
												<h3 className={'SECTION__title font-xxxl' + (val1.position === 'center' ? ' text-center' : ' text-left')} dangerouslySetInnerHTML={{ __html: val1.title }}></h3>
											}

											{
												val1.subtitle &&
												<h4 className={'SECTION__subtitle' + (val1.position === 'center' ? ' text-center' : ' text-left')} dangerouslySetInnerHTML={{ __html: val1.subtitle }}></h4>
											}

											{
												val1.buttons && !auth.isAuthenticated &&
												<div className="SECTION__item">
													<div className="row">
														{
															_.map(val1.buttons, (val2, i2) => {
																if (val2.link) {
																	return (
																		<div key={i2} className={val2.gridClasses}>
																			<Link to={slugByPageId(val2.link, lang, routes)} className="BUTTON">{ val2.text }</Link>
																		</div>
																	)
																}

																if (val2.url) {
																	return (
																		<div key={i2} className={val2.gridClasses}>
																			<a href={val2.url} target="_blank" className="BUTTON" rel="noreferrer">{ val2.text }</a>
																		</div>
																	)
																}
															})
														}
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						)
					})
				}
				

				{
					_.map(text.benefits, (val1: any, i1: number) => {
						return (
							<div key={i1} className={'SECTION padding-wrap-xl text-left' + (i1 === 0 ? ' has-top-border' : '') + (parseInt(text.benefits.length) - 1 !== i1 ? ' no-bottom-padding' : '')}> 
								{
									val1.hasBackground &&
									<div className="SECTION__bg">
										<div className="SECTION__bg4" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg2.svg)' }}></div>
										<div className="SECTION__bg3" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg1.svg)' }}></div>
									</div>
								}

								<div className="grid">
									<div className={'row vertical-aligned' + (val1.position === 'right' ? ' reversed-cols' : '')}>
										{
											val1.image1 &&
											<div className={'col-12' + (val1.position === 'right' ? ' push-sm-1' : '') + (val1.position !== 'center' ? ' col-sm-6' : ' col-sm-8 push-sm-2 col-md-6 push-md-3')}>
												<Scene>
													{(progress: any, event: any) => (
														<div className="ABOUT__problems-image">
															<ProgressiveImg
																img={false}
																showLoader={true}
																shouldLoad={event.type === 'start'}
																lowQualitySrc=""
																highQualitySrc={process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/el/' +  val1.image1}
															/>
														</div>
													)}
												</Scene>
											</div>
										}
										
										<div className={'col-12' + (val1.position === 'left' ? ' push-sm-1' : '') + (val1.position !== 'center' ? ' col-sm-5' : ' col-sm-8 push-sm-2 col-md-6 push-md-3')}>
											{
												val1.preTitle &&
												<div className={'SECTION__pretitle' + (val1.position === 'center' ? ' text-center' : ' text-left')}>
													<span>{ val1.preTitle }</span>
												</div>
											}

											{
												val1.title &&
												<h3 className={'SECTION__title font-xxxl' + (val1.position === 'center' ? ' text-center' : ' text-left')} dangerouslySetInnerHTML={{ __html: val1.title }}></h3>
											}

											{
												val1.subtitle &&
												<h4 className={'SECTION__subtitle' + (val1.position === 'center' ? ' text-center' : ' text-left')} dangerouslySetInnerHTML={{ __html: val1.subtitle }}></h4>
											}

											{
												val1.text &&
												<div className={'TEXT' + (val1.position === 'center' ? ' text-center' : ' text-left')} dangerouslySetInnerHTML={{ __html: val1.text }}></div>
											}

											{
												val1.buttons && 
												!auth.isAuthenticated &&
												<div className="SECTION__item">
													<div className="row">
														{
															_.map(val1.buttons, (val2, i2) => {
																if (val2.link) {
																	return (
																		<div key={i2} className={val2.gridClasses}>
																			<Link to={slugByPageId(val2.link, lang, routes)} className="BUTTON">{ val2.text }</Link>
																		</div>
																	)
																}

																if (val2.url) {
																	return (
																		<div key={i2} className={val2.gridClasses}>
																			<a href={val2.url} target="_blank" className="BUTTON" rel="noreferrer">{ val2.text }</a>
																		</div>
																	)
																}
															})
														}
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						)
					})
				}

				{
					text.values &&
					<div className="SECTION has-top-border padding-wrap-xl text-left">
						<div className="grid">
							<div className="row vertical-aligned">
								<div className="col-12 col-md-6 push-md-3 col-lg-8 push-lg-2">
									{
										text.values?.preTitle &&
										<div className="SECTION__pretitle text-center">
											<span>{ text.values?.preTitle }</span>
										</div>
									}

									{
										text.values?.title &&
										<h3 className="SECTION__title font-xxxl text-center" dangerouslySetInnerHTML={{ __html: text.values?.title }}></h3>
									}

									{
										text.values?.subtitle &&
										<h4 className="SECTION__subtitle text-center" dangerouslySetInnerHTML={{ __html: text.values?.subtitle }}></h4>
									}
								</div>

								<div className="col-12">
									<div className="ABOUT__values">
										<div className="row">
											{
												_.map(text.values?.values, (val1, i1) => {
													return (
														<div key={i1} className="col-12 col-sm-6 col-md-4">
															<div className="ABOUT__values-item" dangerouslySetInnerHTML={{ __html: val1 }}></div>
														</div>
													)
												})
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{
					text.video &&
					<div className="SECTION has-top-border padding-wrap-xl text-left">
						<div className="grid">
							<div className="row vertical-aligned text-center">
								<div className="col-12 col-sm-8 push-sm-2">
									{
										text.video?.preTitle &&
										<div className="SECTION__pretitle">
											<span>{ text.video?.preTitle }</span>
										</div>
									}

									{
										text.video?.title &&
										<h3 className="SECTION__title font-xxxl" dangerouslySetInnerHTML={{ __html: text.video?.title }}></h3>
									}

									{
										text.video?.subtitle &&
										<h4 className="SECTION__subtitle" dangerouslySetInnerHTML={{ __html: text.video?.subtitle }}></h4>
									}

									<div className="ABOUT__video">
										<div className="ABOUT__video-inner">
											<iframe src={text.video?.url} title={text.video?.title} frameBorder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
										</div>
									</div>
								</div>

								{
									text.video?.buttons && !auth.isAuthenticated &&
									<div className="col-12">
										<div className="SECTION__item">
											<div className="row">
												{
													_.map(text.video?.buttons, (val1, i1) => {
														return (
															<div key={i1} className={val1.gridClasses}>
																<Link to={slugByPageId(val1.link, lang, routes)} className="BUTTON">{ val1.text }</Link>
															</div>
														)
													})
												}
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				}

				{
					text.guide &&
					<div className="SECTION has-top-border padding-wrap-xl">
						<div className="SECTION__bg">
							<div className="SECTION__bg5" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg1.svg)'}}></div>
							<div className="SECTION__bg6" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg2.svg)'}}></div>
						</div>
						
						<div className="grid">
							<div className="row">
								{
									text.guide?.title &&
									<div className="col-12">
										{
											text.guide?.preTitle &&
											<div className="SECTION__pretitle text-center">
												<span>{ text.guide?.preTitle }</span>
											</div>
										}

										{
											text.guide?.title &&
											<h1 className="SECTION__title font-xxxl text-center" dangerouslySetInnerHTML={{ __html: text.guide?.title }}></h1>
										}

										{
											text.guide?.subtitle &&
											<h2 className="SECTION__subtitle text-center" dangerouslySetInnerHTML={{ __html: text.guide?.subtitle }}></h2>
										}
									</div>
								}

								{
									text.guide?.items &&
									<div className="col-12">
										<div className="ABOUT__guide">
											<div className="row">
												{
													_.map(text.guide?.items, (val1: any, i1: number) => {
														return (
															<div key={i1} className={'col-12 col-sm-8 push-sm-2 col-md-6' + (i1 % 2 ? ' push-md-4' : '')}>
																<div className="SECTION__item">
																	<div className="SECTION__inner bg-diff1">
																		<div className="grid">
																			<div className="row">
																				<div className="col-12">
																					
																					<div className="ABOUT__guide-item">
																						{
																							val1.number &&
																							<div className="ABOUT__guide-number">{ val1.number }</div>
																						}

																						{
																							val1.title &&
																							<h5 className="SECTION__title font-lg">
																								{ val1.title }
																							</h5>
																						}

																						{
																							val1.text &&
																							<div className="ABOUT__guide-text" dangerouslySetInnerHTML={{ __html: val1.text }}></div>
																						}
																					</div>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														)
													})
												}
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				}

				{
					text.faq &&
					<div className="SECTION text-left">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									{
										text.faq?.preTitle &&
										<div className="SECTION__pretitle text-center">
											<span>{ text.faq?.preTitle }</span>
										</div>
									}

									{
										text.faq?.title &&
										<h3 className="SECTION__title font-xxxl text-center" dangerouslySetInnerHTML={{ __html: text.faq?.title }}></h3>
									}

									{
										text.faq?.subtitle &&
										<h4 className="SECTION__subtitle text-center" dangerouslySetInnerHTML={{ __html: text.faq?.subtitle }}></h4>
									}
								</div>

								<div className="col-12 col-md-6">
									<div className="SECTION__item">
										<div className="SECTION__inner bg-diff1">
											<div className="grid">
												<div className="row">
													<div className="col-12">
													
														{
															text.faq?.items?.length > 0 &&
															<div className="ABOUT__faq">
																{
																	_.map(text.faq?.items, (val1: any, i1: number) => {
																		let index: number = i1 + 1;

																		if (index > (text.faq?.items.length / 2)) { return null; }
																		
																		return (
																			<div key={i1} className={'ABOUT__faq-item' + (faqItemExpandedId === index ? ' active' : '')}>
																				{
																					val1.title &&
																					<div className="ABOUT__faq-item-title" onClick={() => setFaqItemExpandedId(faqItemExpandedId === index ? 0 : index)}>
																						<span className="SECTION__subtitle"><strong>{ val1.title }</strong></span>
																						<span className={generalText.other?.sliderArrowsSmall}></span>
																					</div>
																				}

																				{
																					val1.text &&
																					<div className="ABOUT__faq-item-text" dangerouslySetInnerHTML={{ __html: val1.text }}></div>
																				}
																			</div>
																		)
																	})
																}
															</div>
														}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div className="col-12 col-md-6">
									<div className="SECTION__item">
										<div className="SECTION__inner bg-diff1">
											<div className="grid">
												<div className="row">
													<div className="col-12">
													
														{
															text.faq?.items?.length > 0 &&
															<div className="ABOUT__faq">
																{
																	_.map(text.faq?.items, (val1: any, i1: number) => {
																		let index: number = i1 + 1;

																		if (index <= (text.faq?.items.length / 2)) { return null; }
																		
																		return (
																			<div key={i1} className={'ABOUT__faq-item' + (faqItemExpandedId === index ? ' active' : '')}>
																				{
																					val1.title &&
																					<div className="ABOUT__faq-item-title" onClick={() => setFaqItemExpandedId(faqItemExpandedId === index ? 0 : index)}>
																						<span className="SECTION__subtitle"><strong>{ val1.title }</strong></span>
																						<span className={generalText.other?.sliderArrowsSmall}></span>
																					</div>
																				}

																				{
																					val1.text &&
																					<div className="ABOUT__faq-item-text" dangerouslySetInnerHTML={{ __html: val1.text }}></div>
																				}
																			</div>
																		)
																	})
																}
															</div>
														}
													</div>
												</div>
											</div>
										</div>
									</div>

									{
										text.faq.buttons &&
										<div className="SECTION__item">
											<div className="row">
												{
													_.map(text.faq.buttons, (val1, i2) => {
														if (val1.link) {
															return (
																<div key={i2} className={val1.gridClasses}>
																	<Link to={slugByPageId(val1.link, lang, routes)} className="BUTTON">{ val1.text }</Link>
																</div>
															)
														}

														if (val1.url) {
															return (
																<div key={i2} className={val1.gridClasses}>
																	<a href={val1.url} target="_blank" className="BUTTON" rel="noreferrer">{ val1.text }</a>
																</div>
															)
														}
													})
												}
											</div>
										</div>
									}
								</div>
							</div>
						</div>
					</div>
				}

				{
					testimonials?.length > 0 && text.testimonials?.title &&
					<div className="SECTION has-top-border ABOUT__testimonials padding-wrap-xl text-left overflow-hidden">
						<div className="SECTION__bg">
							<div className="SECTION__bg5" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg4.svg)'}}></div>
							<div className="SECTION__bg6" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/assets/' + process.env?.REACT_APP_NAME?.toLowerCase() + '/bg/bg3.svg)'}}></div>
						</div>

						<div className="grid">
							<div className="row vertical-aligned">
								<div className="col-12">
									{
										text.testimonials?.preTitle &&
										<div className="SECTION__pretitle text-center">
											<span>{ text.testimonials?.preTitle }</span>
										</div>
									}

									{
										text.testimonials?.title &&
										<h3 className="SECTION__title font-xxxl text-center" dangerouslySetInnerHTML={{ __html: text.testimonials?.title }}></h3>
									}

									{
										text.testimonials?.subtitle &&
										<h4 className="SECTION__subtitle text-center" dangerouslySetInnerHTML={{ __html: text.testimonials?.subtitle }}></h4>
									}
									
									<Swiper
										speed={300}
										modules={[Scrollbar, Autoplay]}
										slidesPerView={1}
										loop={false}
										spaceBetween={20}
										scrollbar={{
											'el': '.ABOUT__testimonials .SLIDER__scrollbar',
											'draggable': true,
										}}
										breakpoints={{
											768: {
												'slidesPerView': 2,
												'spaceBetween': 20
											},
											1024: {
												'slidesPerView': 3,
												'spaceBetween': 20
											}
										}}
									>
										{
											_.map(testimonials, (val1, i1) => {
												return (
													<SwiperSlide key={i1}>
														<div className="SECTION__item">
															<div className="ABOUT__testimonials-item">
																<div className="ABOUT__testimonials-item-quote">“</div>

																{
																	(val1.name && val1.slug) &&
																	<Link to={slugByPageId('profile', lang, routes).replace(':slug', val1.slug)} className="ABOUT__testimonials-item-title LINK">
																		{ val1.name }
																	</Link>
																}

																{
																	(val1.name && !val1.slug) &&
																	<div className="ABOUT__testimonials-item-title">
																		{ val1.name }
																	</div>
																}

																{
																	val1.slug &&
																	<div className="ABOUT__testimonials-item-slug">@{val1.slug}</div>
																}

																{
																	val1.text &&
																	<p>{ val1.text }</p>
																}
															</div>
														</div>
													</SwiperSlide>
												)
											})
										}
									</Swiper>

									<div className="SLIDER__scrollbar swiper-scrollbar">
										<div className="swiper-scrollbar-drag"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{
					text.outro &&
					!auth.isAuthenticated &&
					<div className="SECTION has-top-border padding-wrap-xl">
						<div className="grid">
							<div className="row">
								{
									text.outro?.title &&
									<div className="col-12 col-sm-8 push-sm-2">
										{
											text.outro?.preTitle &&
											<div className="SECTION__pretitle text-center">
												<span>{ text.outro?.preTitle }</span>
											</div>
										}

										{
											text.outro?.title &&
											<h1 className="SECTION__title font-xxxl text-center" dangerouslySetInnerHTML={{ __html: text.outro?.title }}></h1>
										}

										{
											text.outro?.subtitle &&
											<h2 className="SECTION__subtitle text-center" dangerouslySetInnerHTML={{ __html: text.outro?.subtitle }}></h2>
										}

										{
											text.outro?.buttons && !auth.isAuthenticated &&
											<div className="SECTION__item">
												<div className="row">
													{
														_.map(text.outro?.buttons, (val1, i1) => {
															return (
																<div key={i1} className={val1.gridClasses}>
																	<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.diff ? ' ' + val1.diff : '')}>{ val1.text }</Link>
																</div>
															)
														})	
													}
												</div>
											</div>
										}
									</div>
								}
							</div>
						</div>
					</div>
				}
			</Controller>

			<Footer />
		</div>
	)
}

export default About;