declare const window: any;

export const initGoogle = () => {
	if (window.gtag || process.env?.REACT_APP_ENV === 'development') { return; }

	let key: string = '';

	if (process.env?.REACT_APP_NAME === 'FindUpp') {
		key = 'G-R94EHV7ZDE';
	}

	if (process.env?.REACT_APP_NAME === 'TekBruv') {
		key = 'G-NT1DGCV7YM'
	}

	const script = document.createElement('script');
	script.async = true;
	script.src = `https://www.googletagmanager.com/gtag/js?id=G-R94EHV7ZDE`;
	document.body.appendChild(script);

	script.onload = () => {
		window.dataLayer = window.dataLayer || [];

		//@ts-ignore
		function gtag() {
			window.dataLayer.push(arguments);
		}
		//@ts-ignore
		gtag('js', new Date());
		//@ts-ignore
		gtag('config', key);
	};
}

export const initMeta = () => {
	if (window.fbq || process.env?.REACT_APP_ENV === 'development') { return; }

	let key: string = '';

	if (process.env?.REACT_APP_NAME === 'FindUpp') {
		key = '470255415217516';
	}

	if (process.env?.REACT_APP_NAME === 'TekBruv') {
		key = '1080430300187340'
	}

	//@ts-ignore
	(function(f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
		//@ts-ignore
		if (f.fbq) return;
		//@ts-ignore
		n = f.fbq = function() {
		//@ts-ignore
		n.callMethod ?
		//@ts-ignore
		n.callMethod.apply(n, arguments) : n.queue.push(arguments);
		//@ts-ignore
		};
		//@ts-ignore
		if (!f._fbq) f._fbq = n;
		//@ts-ignore
		n.push = n;
		//@ts-ignore
		n.loaded = !0;
		//@ts-ignore
		n.version = '2.0';
		//@ts-ignore
		n.queue = [];
		//@ts-ignore
		t = b.createElement(e);
		//@ts-ignore
		t.async = !0;
		//@ts-ignore
		t.src = v;
		//@ts-ignore
		s = b.getElementsByTagName(e)[0];
		//@ts-ignore
		s.parentNode?.insertBefore(t, s);
		//@ts-ignore
	})(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

	// Initialize Facebook Pixel with the provided Pixel ID
	window.fbq('init', key);
};

export const initHotjar = (): void => {
	if (process.env?.REACT_APP_ENV === 'development') { return; }

	let key: number = 0;

	if (process.env?.REACT_APP_NAME === 'FindUpp') {
		key = 3551104;
	}

	if (process.env?.REACT_APP_NAME === 'TekBruv') {
		key = 5120335
	}
	
	const hjid: any = key;
	const hjsv: any = 6;

	//@ts-ignore
	(function(h: Window, o: Document, t: string, j: string, a: HTMLElement | null, r: HTMLScriptElement) {
	//@ts-ignore
	h.hj = h.hj || function() {
	//@ts-ignore
	(h.hj.q = h.hj.q || []).push(arguments);
	//@ts-ignore
	};
	//@ts-ignore
	h._hjSettings = { hjid, hjsv };
	//@ts-ignore
	a = o.getElementsByTagName('head')[0];
	//@ts-ignore
	r = o.createElement('script');
	//@ts-ignore
	r.async = true;
	//@ts-ignore
	r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
	//@ts-ignore
	a.appendChild(r);
	//@ts-ignore
	})(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=', null, null);
};