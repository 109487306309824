import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Controller } from 'react-scrollmagic';
import parse from 'html-react-parser';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { checkCookie } from './../../other/helpers/cookies';
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import components
import Popup from './../../components/popup/popup';
import Loader from './../../components/loader/loader';

import PageHeader from './../../components/page-header/page-header';
import PostItem from './../../components/post-item/post-item';
import Input from './../../components/input/input';
import Textarea from './../../components/textarea/textarea';

// Import styles
import './post.scss';

interface props {
	'pageId': string
}

const Post = (props: props) => {
	const { id }: any = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initReport: {[key: string]: any} = {'report': ''};
	const initComment: {[key: string]: any} = {
		'comment': '',
	}
	const initPopups: {[key: string]: any} = {
		'postToReportId': '',
		'postToRemoveId': '',
		'commentToReportId': '',
		'commentToRemoveId': '',
		'showNewCommentPopup': false
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [clickedUrlCheck, setClickedUrlCheck] = useState<{[key: string]: any}>({});
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [post, setPost] = useState<{[key: string]: any}>({});
	const [text, setText] = useState<{[key: string]: any}>({});
	const [comments, setComments] = useState<Array<any>>([]);
	const [commentsPage, setCommentsPage] = useState<number>(1);
	const [commentsTotal, setCommentsTotal] = useState<number>(1);
	const [commentsFromDate, setCommentsFromDate] = useState<string>('');
	const [comment, setComment] = useState<{[key: string]: any}>(initComment);
	const [report, setReport] = useState<{[key: string]: any}>(initReport);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);
	const [errors, setErrors] = useState<{[key: string]: any}>({});

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			onGetPost(id);
		}).catch((err) => {			
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId, id]);

	const onGetPost: any = (id: string) => {
		setIsLoading(true);

		api.get('/get-post/' + id).then(async (res) => {
			if (!res.data[0]) {
				return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
			}

			setPost(res.data[0]);
			setIsLoading(false);

			if (res.data[0].status !== 2) {
				onGetComments(1, true);
			}
			
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	const onPostRemove = () => {
		setIsLoading(true);

		let postData: any = {
			'id': popups.postToRemoveId
		}
		
		// Remove post
		api.put('/remove-post', postData).then((res) => {
			setPopups(initPopups);

			return navigate(slugByPageId('feed', lang, routes), {'replace': true});

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onPostReport = () => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(report);

		// Validation
		let fieldErrors = validateFields(stateClone, ['report']);

		if (!_.isEmpty(fieldErrors)) {
			setErrors(fieldErrors);
			setIsLoading(false);

			return;
		}

		let reportData: any = {
			'id': popups.postToReportId,
			'type': 'Post',
			'report': report.report
		}

		// Report post
		api.post('/add-report', reportData).then((res) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(generalText.messages?.itemReportedMessage);
			setIsLoading(false);

		}).catch((err) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		
		});
	}
	
	const onToggleLikeDislike = async (postId: string, isLiked: boolean) => {
		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}
		
		// Check if user has selected his main profile
		if (!auth.mainProfile?.slug) {
			setNotifications(generalText.messages?.profileIsNotSelected);

			return;
		}

		let likeData: any = {
			'isLiked': isLiked,
			'postId': postId,
			'profileSlug': auth.mainProfile?.slug
		}

		// Add or remove like / dislike 
		await new Promise((resolve, reject) => {
			api.post('/toggle-like-dislike', likeData).then((res1) => {
				resolve(true);
				
			}).catch((err) => {
				setNotifications(err.response?.data?.messages);
				reject(false);
				return;
			});
		});

		getPost(postId);
	}

	const getPost = (postId: string) => {
		api.get('/get-post/' + postId).then((res1) => {
				
			// Replaced post with an updated version
			onPostUpdate(res1.data[0]);
			setIsLoading(false);

		}).catch((err) => {
			setIsLoading(false);

			setNotifications(err.response?.data?.messages);
		});
	}

	const onPostUpdate = (updatedPost: {[key: string]: any}) => {
		// Replaced post with an updated version
		setPost(updatedPost);
	}

	const onGetComments = async (page: number, hasLoader: boolean) => {
		if (!auth.isAuthenticated && page > 1) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		if (hasLoader) {
			setIsLoading(true);
		}

		// Reinit state if this is the first page
		if (page === 1) {
			setComments([]);
			setCommentsPage(1);
			setCommentsTotal(1);
			setCommentsFromDate(new Date().toString());
		}

		api.get('/get-comments/' + id + '/' + (page === 1 ? 'null' : commentsFromDate) + '/' + page).then((res) => {

			// Push new comments into cloned state
			setCommentsPage(page + 1);
			setComments(posts => [...posts, ...res.data.entries]);

			// Get count of total comments and first comment's date from DB
			if (page === 1) {
				setCommentsTotal(res.data?.total || 0);
			}

			if (hasLoader) {
				setIsLoading(false);
			}

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);

			if (hasLoader) {
				setIsLoading(false);
			}

		});
	}

	const onBeforeAddComment = () => {
		setPopups(initPopups);

		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		if (!auth.mainProfile?.slug) {
			setNotifications(generalText.messages?.profileIsNotSelected);

			return;
		}

		if (auth.mainProfile?.status !== 1) {
			setNotifications(generalText.messages?.profileNotConfirmedMessage);

			return;
		}

		setPopups(prevState => ({...initPopups, 'showNewCommentPopup': true}));
	}

	const onAddComment = (e: any) => {
		e.preventDefault();

		setIsLoading(true);

		let stateClone = _.cloneDeep(comment);
		let fieldErrors = validateFields(stateClone, ['comment']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		let formData: any = {
			'postId': post._id,
			'comment': stateClone.comment
		}

		api.post('/add-comment', formData).then((res1) => {

			setComment(initComment);
			setPopups(initPopups);
			
			setNotifications(generalText.messages?.commentSubmittedMessage);

			setIsLoading(false);
			onGetPost(id);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {

			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	const onCommentRemove = () => {
		setIsLoading(true);

		let commentData: any = {
			'id': popups.commentToRemoveId
		}
		
		// Remove comment
		api.put('/remove-comment', commentData).then((res) => {

			setPopups(initPopups);
			setIsLoading(false);
			onGetPost(id);

		}).catch((err) => {

			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	const onCommentReport = () => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(report);

		// Validation
		let fieldErrors = validateFields(stateClone, ['report']);

		if (!_.isEmpty(fieldErrors)) {
			setErrors(fieldErrors);
			setIsLoading(false);

			return;
		}

		let reportData: any = {
			'id': popups.commentToReportId,
			'type': 'Comment',
			'report': report.report
		}

		// Report comment
		api.post('/add-report', reportData).then((res) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(generalText.messages?.itemReportedMessage);
			setIsLoading(false);

		}).catch((err) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);

		});
	}

	const onOpenExternalLink = () => {
		onAddActionStats(clickedUrlCheck.slug, {'type': 'click', 'value': clickedUrlCheck.url});

		let url = clickedUrlCheck.url;

		if (!url.match(/^https?:\/\//i)) {
			url = 'https://' + url;
		}

		setClickedUrlCheck({});

		window.open(url, '_blank');
	}

	const onAddActionStats = (slug: string, data: {[key: string]: any}) => {
		let actionStatsData: object = {
			'slug': slug,
			...data
		}

		api.post('add-action', actionStatsData);
	}

	let profileImage: string = '';

	if (auth.mainProfile?.images?.[0]?.path) {
		profileImage = apiBasePath + '/uploads/' + (checkCookie('webpSupported') ? auth.mainProfile?.images[0].path.replace(/[.jpg|.jpeg|.png]{4}$/gi, '.webp') : auth.mainProfile?.images[0].path);
	}

	if (_.isEmpty(text) || _.isEmpty(post)) { return null; }

	return (
		<div className="POST">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle.replace('%repStr1%', post.profile?.name).replace('%repStr2%', post.kind) }</title>
				<meta name="description" content={text.pageDescription.replace('%repStr1%', post.text?.[lang].text)} />
			</Helmet>

			<Loader active={isLoading} fixed={true} />

			<Popup
				title={text.reportPostPopup?.title}
				submitBtn={text.reportPostPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.postToReportId !== ''}
				onSubmit={() => onPostReport()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className={'FIELD' + (errors.report ? ' error' : '')}>
										<Textarea
											value={report.report}
											placeholder={text.reportPostPopup?.input1?.placeholder}
											label={text.reportPostPopup?.input1?.label}
											onChange={(val: string) => setReport(prevState => ({...prevState, 'report': val}))}
										/>

										{
											errors.report &&
											<p className="error">
												{ errors.report }
											</p>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.removePostPopup?.title}
				text={text.removePostPopup?.text1}
				submitBtn={text.removePostPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.postToRemoveId !== ''}
				onSubmit={() => onPostRemove()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={text.reportCommentPopup?.title}
				submitBtn={text.reportCommentPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.commentToReportId !== ''}
				onSubmit={() => onCommentReport()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							<div className="col-12">
								<div className="SECTION__item">
									<div className={'FIELD' + (errors.report ? ' error' : '')}>
										<Textarea
											value={report.report}
											placeholder={text.reportCommentPopup?.input1?.placeholder}
											label={text.reportCommentPopup?.input1?.label}
											onChange={(val: string) => setReport(prevState => ({...prevState, 'report': val}))}
										/>

										{
											errors.report &&
											<p className="error">
												{ errors.report }
											</p>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={text.removeCommentPopup?.title}
				text={text.removeCommentPopup?.text1}
				submitBtn={text.removeCommentPopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.commentToRemoveId !== ''}
				onSubmit={() => onCommentRemove()}
				onClose={() => setPopups(initPopups)}
			/>

			<Popup
				title={generalText.externalLinkPopup?.title}
				closeBtn={generalText?.generalPopup?.button1?.text}
				submitBtn={generalText.externalLinkPopup?.button1?.text}
				active={!_.isEmpty(clickedUrlCheck)}
				onSubmit={() => onOpenExternalLink()}
				onClose={() => setClickedUrlCheck({})}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								generalText.externalLinkPopup?.text1 &&
								<div className="col-12">
									{
										parse(generalText.externalLinkPopup?.text1, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <strong>{ clickedUrlCheck.url }</strong>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			{
				(
					post.kind.toLowerCase() === 'feedback' ||
					post.kind.toLowerCase() === 'question'
				) &&
				<Popup
					title={text.composeCommentPopup?.title}
					closeBtn={generalText?.generalPopup?.button1?.text}
					submitBtn={text.composeCommentPopup?.button1?.text}
					active={popups.showNewCommentPopup}
					onClose={() => setPopups(initPopups)}
					onSubmit={(e: any) => onAddComment(e)}
				>
					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								{
									!auth.mainProfile?.name && text.composeCommentPopup?.text3 &&
									<div className="col-12">
										{ text.composeCommentPopup?.text3 }
									</div>
								}

								<div className="col-12">
									<div className={'FIELD' + (errors.comment ? ' error' : '')}>
										<Textarea
											value={comment.comment}
											label={text.composeCommentPopup?.textarea1?.label}
											placeholder={text.composeCommentPopup?.textarea1?.placeholder}
											onChange={(val: string) => setComment(prevState => ({...prevState, 'comment': val}))}
											minCharLength={2}
											maxCharLength={10000}
										/>

										{
											errors.comment &&
											<p className="error">
												{ errors.comment }
											</p>
										}
									</div>
								</div>

								{
									text.composeCommentPopup?.prices?.commentPrice && 
									post.userId !== auth.id &&
									<div className="col-12">
										{
											parse(text.composeCommentPopup?.prices?.commentPrice, {
												replace: (domNode: any) => {
													if (domNode.attribs?.class === '%repStr1%') {
														return <span>{ generalText.keys?.newCommentEarning }</span>;
													}
												}
											})
										}
									</div>
								}
							</div>
						</div>
					</div>
				</Popup>
			}

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #346346235135']}
				active={!_.isEmpty(notifications?.text)}
				closeBtn={generalText?.generalPopup?.button1?.text}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				<PageHeader
					h1={post.kind?.toLowerCase() === 'educational' ? true : false}
					heading={text.pageTitle.replace('%repStr1%', post.profile?.name).replace('%repStr2%', post.kind)}
				/>

				<div className="SECTION">
					<div className="grid">
						<div className="row">
							<div className={post.kind?.toLowerCase() !== 'articles' ? 'col-12 col-sm-8 push-sm-2 col-md-6 push-md-3' : 'col-12'}>
								<div className="SECTION__item">
									<div className="POST">
										<PostItem
											postId={post._id}
											userId={post.userId}
											profileId={post.profileId}
											isProfileVisible={post.profile?.isVisible}
											profileStatus={post.profile?.status}
											profileSlug={post.profile?.slug}
											name={post.profile?.name}
											images={post.profile?.images}
											profileNetworks={post.profile?.networks}
											text={post.text?.[lang]}
											country={post.country}
											city={post.city}
											type={post.type}
											category={post.category}
											interest={post.interest}
											likes={post.likes}
											dislikes={post.dislikes}
											isLiked={post.isLiked}
											isDisliked={post.isDisliked}
											comments={post.comments}
											createdAt={post.createdAt}
											status={post.status}
											statusMessage={post.statusMessage}
											kind={post.kind}
											clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
											postToReportId={(val: string) => setPopups({...initPopups, 'postToReportId': val})}
											postToRemoveId={(val: string) => setPopups({...initPopups, 'postToRemoveId': val})}
											onViewStatusMessage={(val: string) => setNotifications({'title': text.rejectionTitle, 'text': [val]})}
											onToggleLikeDislike={(postId: string, isLiked: boolean) => onToggleLikeDislike(postId, isLiked)}
										/>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				
				{
					(
						post.kind.toLowerCase() === 'feedback' ||
						post.kind.toLowerCase() === 'question'
					) &&
					<div className="SECTION">
						<div className="grid">
							<div className="row">
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="row">
										
										{
											auth.isAuthenticated &&
											<div className="col-12">
												<div className="POST__input">
													<div className="PROFILEIMAGE diff1">
														{
															auth.isAuthenticated &&
															<Link to={slugByPageId('user-profiles', lang, routes)} className="LINK"></Link>
														}

														<span className="PROFILEIMAGE__inner">
															<span className={generalText.profileItem?.hiddenProfile?.icon}></span>
															<span className="image" style={{ backgroundImage: 'url(' + profileImage + ')' }}></span>
														</span>
													</div>

													<div className="POST__input-message">
														<Input
															value=""
															label={text.comments?.compose?.input1?.label.replace('%repStr1%', (auth.mainProfile?.name || ' user'))}
															onChange={() => {}}
															type="text"
															placeholder={text.comments?.compose?.input1?.placeholder}
														/>

														<div onClick={() => onBeforeAddComment()} className="POST__input-message-button"></div>
													</div>
												</div>
											</div>
										}

										{
											comments?.length > 0 &&
											<>
												{
													_.map(comments, (val1, i1) => {
														return (
															<div key={i1} className="col-12">
																<div className="SECTION__item">
																	<PostItem
																		postId={val1._id}
																		userId={val1.senderUserId}
																		profileId={val1.profileId}
																		isProfileVisible={val1.profile?.isVisible}
																		profileStatus={val1.profile?.status}
																		profileSlug={val1.profile?.slug}
																		name={val1.profile?.name}
																		images={val1.profile?.images}
																		text={val1.text?.[lang]}
																		comments={val1.comments}
																		createdAt={val1.createdAt}
																		clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
																		postToReportId={(val: string) => setPopups({...initPopups, 'commentToReportId': val})}
																		postToRemoveId={(val: string) => setPopups({...initPopups, 'commentToRemoveId': val})}
																	/>
																</div>
															</div>
														)
													})
												}

												{
													comments?.length > 0 && 
													(commentsTotal > comments.length) && 
													text.comments?.itemsEnd?.button1?.text &&
													<div className="col-6 push-3">
														<div className="SECTION__item">
															<button onClick={() => onGetComments(commentsPage, true)} className="BUTTON">{ text.comments?.itemsEnd?.button1?.text }</button>		
														</div>
													</div>
												}
												
												{
													(commentsTotal <= comments.length) && 
													text.comments?.itemsEnd?.button1?.disabledText &&
													<div className="col-6 push-3">
														<div className="SECTION__item">
															<button className="BUTTON diff1" disabled={true}>{ text.comments?.itemsEnd?.button1?.disabledText }</button>
														</div>
													</div>
												}
											</>
										}
									</div>
								</div>
							</div>

							{
								(
									!isLoading && 
									comments.length <= 0 &&
									post.kind.toLowerCase() === 'feedback'
								) &&
								<div className="SECTION padding-wrap-xl text-center">
									<div className="grid">
										<div className="row">
											<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
												{
													text.comments?.noFeedbackResults?.icon &&
													<div className="SECTION__icon">
														<div className={text.comments?.noFeedbackResults?.icon}></div>
													</div>
												}
												
												{
													text.comments?.noFeedbackResults?.title &&
													<div className="SECTION__title">{ text.comments?.noFeedbackResults?.title }</div>
												}
												
												{
													text.comments?.noFeedbackResults?.subtitle &&
													<div className="SECTION__subtitle">{ text.comments?.noFeedbackResults?.subtitle }</div>
												}
												
												{
													text.comments?.noFeedbackResults?.text &&
													<div dangerouslySetInnerHTML={{ __html: text.comments?.noFeedbackResults?.text }}></div>
												}

												{
													text.comments?.noFeedbackResults?.buttons?.length > 0 &&
													<div className="SECTION__item">
														<div className="row">
															{
																_.map(text.comments?.noFeedbackResults?.buttons, (val1, i1) => {
																	return (
																		<div key={i1} className={val1.gridClasses}>	
																			<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</Link>
																		</div>
																	)
																})
															}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							}

							{
								(
									!isLoading && 
									comments.length <= 0 &&
									post.kind.toLowerCase() !== 'feedback'
								) &&
								<div className="SECTION padding-wrap-xl text-center">
									<div className="grid">
										<div className="row">
											<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
												{
													text.comments?.noResults?.icon &&
													<div className="SECTION__icon">
														<div className={text.comments?.noResults?.icon}></div>
													</div>
												}
												
												{
													text.comments?.noResults?.title &&
													<div className="SECTION__title">{ text.comments?.noResults?.title }</div>
												}
												
												{
													text.comments?.noResults?.subtitle &&
													<div className="SECTION__subtitle">{ text.comments?.noResults?.subtitle }</div>
												}
												
												{
													text.comments?.noResults?.text &&
													<div dangerouslySetInnerHTML={{ __html: text.comments?.noResults?.text }}></div>
												}

												{
													text.comments?.noResults?.buttons?.length > 0 &&
													<div className="SECTION__item">
														<div className="row">
															{
																_.map(text.comments?.noResults?.buttons, (val1, i1) => {
																	return (
																		<div key={i1} className={val1.gridClasses}>	
																			<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</Link>
																		</div>
																	)
																})
															}
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							}
						</div>
					</div>
				}
			</Controller>
		</div>
	);
}

export default Post;