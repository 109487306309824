import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Scene } from 'react-scrollmagic';
import { Tooltip } from 'react-tooltip';
import moment from 'moment';
import _ from 'lodash';

// Import configs
import { apiBasePath } from './../../other/configs/urls';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import ProgressiveImg from './../../components/progressive-img/progressive-img';

// Import styles
import 'react-tooltip/dist/react-tooltip.css';
import './profile-item.scss';

interface props {
	'layout'?: string,

	'userId': string,
	'profileId': string,
	'isVisible': boolean,
	'isContactable'?: boolean,
	'isFavorite'?: boolean,
	'name'?: string,
	'slug'?: string,
	'covers'?: Array<any>,
	'images'?: Array<any>,
	'types'?: Array<any>,
	'offer'?: Array<any>,
	'category'?: string,
	'country'?: string,
	'city'?: string,
	'about'?: string,
	'reason'?: string,
	'interests'?: Array<any>,
	'skills'?: Array<any>,
	'networks'?: Array<any>,
	'status'?: number,
	'statusMessage'?: string,
	'valueRating'?: number,
	'raisedAt'?: string,
	'isUnclaimed'?: boolean,
	'blocked'?: boolean,
	'favorited'?: boolean,
	'activeAt'?: string,

	'actionStats'?: any,

	'clickedUrlCheck'?: any,
	'favoriteToRemoveId'?: any,
	'profileToSetMainId'?: any,
	'profileToRaiseId'?: any,
	'profileToReportId'?: any,
	'profileToBlockUnblockId'?: any,
	'onBeforeSendMessage'?: any,
	'onToggleFavorite'?: any,
	'viewOfferId'?: any,

	'onViewStatusMessage'?: any,
	'setNotifications'?: any
};

const ProfileItem = (props: props) => {
	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const [isAboutExpandable, setIsAboutExpandable] = useState<boolean>(false);

	const aboutRef = useRef<any>(null);

	useEffect(() => {	
		if (
			props.about && 
			aboutRef.current?.clientHeight > 66 &&
			props.onBeforeSendMessage === undefined
		) {
			setIsAboutExpandable(true);
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let profileImage = '';

	if (props.images?.[0]?.path) {
		if (props.isUnclaimed) {
			profileImage = props.images[0].path;
		} else {
			profileImage = apiBasePath + '/uploads/' + props.images[0].path;
		}
	}

	let coverImage: string = '';

	if (props?.covers?.[0]?.path) {
		coverImage = apiBasePath + '/uploads/' + props.covers[0].path;
	} else {
		coverImage = profileImage;
	}

	return (
		<div
			className={
				'PROFILEITEM' + 
				(coverImage && props.layout !== 'xs' ? ' has-cover' : '') +
				(props.activeAt && moment(new Date()).diff(props.activeAt, 'days') > generalText.keys?.daysProfileInactive ? ' inactive': '')
			}
			data-usid={auth.adminType >= 1 ? props.userId : ''}
			data-prid={auth.adminType >= 1 ? props.profileId : ''}
		>
			<div className={'PROFILEITEM__content' + (props.layout === 'xs' ? ' diff1' : '')}>
				{
					(
						props.isVisible ||
						props.userId === auth.id
					) &&
					props.slug &&
					props.onBeforeSendMessage === undefined &&
					props.layout !== 'xs' &&
					<Link to={slugByPageId('profile', lang, routes).replace(':slug', props.slug)} className="PROFILEITEM__link"></Link>
				}

				{
					(
						props.isVisible ||
						props.userId === auth.id
					) && 
					props.layout !== 'xs' &&
					<div className={'PROFILEITEM__cover' + (props.onBeforeSendMessage !== undefined ? ' expandable' : '')}>
							<div className="PROFILEITEM__cover-image">
								<Scene>
									{(progress: any, event: any) => (
										<div className="image">
											<ProgressiveImg
												img={false}
												showLoader={true}
												shouldLoad={true}
												lowQualitySrc=""
												highQualitySrc={coverImage}
											/>
										</div>
									)}
								</Scene>
							</div>
					</div>
				}

				{
					(
						props.isVisible || 
						props.userId === auth.id
					) &&
					<>
						{
							profileImage &&
							<div className={'PROFILEIMAGE' + 
								(props.onBeforeSendMessage !== undefined || !props.slug ? ' diff1' : '') +
								(props.slug === auth.mainProfile?.slug ? ' diff3' : '')
							}>
								{
									props.slug &&
									props.onBeforeSendMessage === undefined && 
									<Link to={slugByPageId('profile', lang, routes).replace(':slug', props.slug)} className="LINK"></Link>
								}
								
								<div className="PROFILEIMAGE__inner">
									<div className={generalText.profileItem?.hiddenProfile?.icon}></div>

									<Scene>
										{(progress: any, event: any) => (
											<div className="image">
												<ProgressiveImg
													img={false}
													noAnimation={true}
													showLoader={true}
													shouldLoad={true}
													lowQualitySrc=""
													highQualitySrc={profileImage}
												/>
											</div>
										)}
									</Scene>
								</div>
							</div>
						}

						{
							props.layout !== 'xs' &&
							<div className="grid">
								<div className="row">
									<div className="col-12">
										<div className="PROFILEITEM__section no-padding text-center">
											
											{
												props.name && 
												<div className="PROFILEITEM__name">
													{
														props.activeAt &&
														moment(new Date()).diff(props.activeAt, 'minutes') < generalText.keys?.minutesProfileIsActive &&
														<span className="PROFILEITEM__name-activestatus"></span>
													}

													{ props.name }
												</div>
											}

											{
												(props.country || props.city) &&
												<div className="PROFILEITEM__location">
													<span className={ generalText.profileItem?.location?.icon}></span> { props.city ? props.city + ', ' : '' }{ props.country }
												</div>
											}

											{
												props.slug &&
												props.layout !== 'xs' &&
												!props.isFavorite &&
												props.profileToSetMainId === undefined &&
												<div className="PROFILEITEM__infobar">
													<strong>
														@{ props.slug }
													</strong>

													{
														props.category &&
														<div>
															{ props.category }
														</div>
													}
													
													{
														props.actionStats?.views > 0 &&
														<span>
															{ props.actionStats.views } views
														</span>
													}
												</div>
											}

											{
												props.slug &&
												props.layout !== 'xs' &&
												!props.isFavorite &&
												props.profileToSetMainId === undefined &&
												(
													props.isUnclaimed !== undefined ||
													props.isContactable !== undefined
												) &&
												<div className="PROFILEITEM__infobar">
													<div className={'PROFILEITEM__infobar-badges' + (!props.about ? ' diff1' : '')}>
														<div id={'badge1-' + props.profileId} data-tooltip-variant="light" className={'PROFILEITEM__infobar-badges-item' + (props.isContactable ? ' active': '')}>
															<span className={generalText.profileItem?.badges?.badge1.icon}></span>	
															
															{
																generalText.profileItem?.badges?.badge1?.tooltipText1 &&
																<Tooltip anchorId={'badge1-' + props.profileId} place="top" className="TOOLTIP">
																	{
																		props.isContactable &&
																		<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge1?.tooltipText1 }</span>
																	}

																	{
																		!props.isContactable &&
																		<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge1?.tooltipText2 }</span>
																	}
																</Tooltip>
															}
														</div>

														{/*
															<div id={'badge2-' + props.profileId} data-tooltip-variant="light" className={'PROFILEITEM__infobar-badges-item' + (props.offer ? ' active': '')}>
																<span className={generalText.profileItem?.badges?.badge2?.icon}></span>
																{
																	generalText.profileItem?.badges?.badge2?.tooltipText1 &&
																	<Tooltip anchorId={'badge2-' + props.profileId} place="top" className="TOOLTIP">
																		{
																			props.offer &&
																			<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge2?.tooltipText1 }</span>
																		}

																		{
																			!props.offer &&
																			<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge2?.tooltipText2 }</span>
																		}
																	</Tooltip>
																}
															</div>
														*/}
														
														<div id={'badge3-' + props.profileId} data-tooltip-variant="light" className={'PROFILEITEM__infobar-badges-item' + (!props.isUnclaimed ? ' active': '')}>
															<span className={generalText.profileItem?.badges?.badge3?.icon}></span>
															
															{
																generalText.profileItem?.badges?.badge3?.tooltipText1 &&
																<Tooltip anchorId={'badge3-' + props.profileId} place="top" className="TOOLTIP">
																	{
																		!props.isUnclaimed &&
																		<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge3?.tooltipText1 }</span>
																	}

																	{
																		props.isUnclaimed &&
																		<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge3?.tooltipText2 }</span>
																	}
																</Tooltip>
															}
														</div>
														
														{
															props.valueRating !== 0 &&
															<div id={'badge4-' + props.profileId} data-tooltip-variant="light" className={'PROFILEITEM__infobar-badges-item' + (props.valueRating === 1 ? ' active': '')}>
																<span className={generalText.profileItem?.badges?.badge4.icon}></span>
																
																{
																	generalText.profileItem?.badges?.badge4?.tooltipText1 &&
																	<Tooltip anchorId={'badge4-' + props.profileId} place="top" className="TOOLTIP">
																		{
																			props.valueRating === 1 &&
																			<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge4?.tooltipText1 }</span>
																		}

																		{
																			props.valueRating === 2 &&
																			<span className="TOOLTIP__text">{ generalText.profileItem?.badges?.badge4?.tooltipText2 }</span>
																		}
																	</Tooltip>
																}
															</div>
														}
													</div>
												</div>
											}
										</div>
									</div>

									{
										auth.isAuthenticated &&
										(
											(
												props.isContactable &&
												props.userId !== auth.id &&
												props.onBeforeSendMessage !== undefined
											) ||
											(
												!props.isFavorite &&
												props.onToggleFavorite !== undefined &&
												props.userId !== auth.id
											) ||
											(
												props.userId === auth.id && 
												props.profileToSetMainId !== undefined
											) ||
											(
												props.userId === auth.id && 
												props.profileToSetMainId !== undefined
											) ||
											(
												generalText.profileItem?.button1?.icon &&
												props.favoriteToRemoveId !== undefined
											) ||
											(
												props.isFavorite && 
												generalText.profileItem?.button1?.icon
											)
										) &&
										<div className="col-12">
											<div className="PROFILEITEM__section text-center">
												<div className="PROFILEITEM__buttons margin-top-sm">
													<div className="PROFILEITEM__buttons-inner">
														{
															props.isContactable &&
															props.userId !== auth.id &&
															props.onBeforeSendMessage !== undefined &&
															<div className="PROFILEITEM__buttons-item has-animation">
																<div className={generalText.profileItem?.button5?.icon} onClick={() => props.onBeforeSendMessage(props.profileId)}></div>
															</div>
														}

														{
															!props.isFavorite &&
															props.onToggleFavorite !== undefined &&
															props.userId !== auth.id &&
															<div className={'PROFILEITEM__buttons-item' + (props.favorited ? ' active' : '')}>
																{
																	!props.favorited &&
																	<span className={generalText.profileItem?.button6?.icon1} onClick={() => props.onToggleFavorite(props.profileId, props.slug)}></span>
																}

																{
																	props.favorited &&
																	<span className={generalText.profileItem?.button6?.icon2} onClick={() => props.onToggleFavorite(props.profileId, props.slug)}></span>
																}
															</div>
														}

														{/*
															props.offer &&
															props.layout === 1 &&
															<div className="PROFILEITEM__buttons-item">
																<div className={generalText.profileItem?.button4?.icon} onClick={() => props.viewOfferId(props.profileId)}></div>
															</div>
														*/}

														{
															props.userId === auth.id && 
															props.profileToSetMainId !== undefined &&
															<div
																className={'PROFILEITEM__buttons-item' + (props.slug === auth.mainProfile?.slug ? ' bg-green' : '')}
																onClick={() => props.profileToSetMainId(props.profileId)}
															>
																<div className={generalText.profileItem?.button7?.icon}></div>
															</div>
														}

														{
															props.userId === auth.id && 
															props.profileToRaiseId !== undefined &&
															<div
																className={'PROFILEITEM__buttons-item' + (
																	props.raisedAt && moment().diff(props.raisedAt, 'hours') < generalText.keys?.hoursToAllowProfileRaise ? 
																	' bg-green' : ' has-animation'
																)}
																onClick={() => props.profileToRaiseId(props.profileId)}
															>
																<div className={generalText.profileItem?.button2?.icon}></div>
															</div>
														}

														{
															props.userId === auth.id && 
															props.profileToSetMainId !== undefined &&
															<Link 
																to={slugByPageId('edit-profile', lang, routes).replace(':id', props.profileId)} 
																className="PROFILEITEM__buttons-item">
																<div className={generalText.profileItem?.button3?.icon}></div>
															</Link>
														}

														{
															generalText.profileItem?.button1?.icon &&
															props.favoriteToRemoveId !== undefined &&
															<div 
																className="PROFILEITEM__buttons-item remove" 
																onClick={() => props.favoriteToRemoveId(props.profileId)}
															>
																<div className={generalText.profileItem?.button1?.icon}></div>
															</div>
														}
													</div>
												</div>
											</div>
										</div>
									}

									{
										auth.isAuthenticated && 
										props.userId === auth.id && 
										props.profileToSetMainId !== undefined &&
										<div className="col-12">
											<div className="PROFILEITEM__section text-center">
												<div className="PROFILEITEM__infobar margin-top-sm">
													<div className="PROFILEITEM__status-textbubbles">
														{
															props.isVisible && 
															generalText.profileItem?.publishedText &&
															<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-green">{ generalText.profileItem?.publishedText }</span>
														}

														{
															!props.isVisible && 
															generalText.profileItem?.unpublishedText &&
															<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-red">{ generalText.profileItem?.unpublishedText }</span>
														}
													</div>

													{
														typeof props.status !== 'undefined' && 
														<span className="PROFILEITEM__status-textbubbles">
															{
																props.status === 0 && 
																generalText.profileItem?.status?.text1 &&
																<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-yellow">{ generalText.profileItem?.status?.text1 }</span>
															}

															{
																props.status === 1 && 
																generalText.profileItem?.status?.text2 &&
																<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-green">{ generalText.profileItem?.status?.text2 }</span>
															}

															{
																props.status === 2 && 
																generalText.profileItem?.status?.text3 &&
																<span className="TEXTBUBBLE has-bg font-xxxxs no-top-margin bg-red clickable" onClick={() => props.onViewStatusMessage(props.statusMessage)}>
																	{ generalText.profileItem?.status?.text3 }
																</span>
															}
														</span>
													}
												</div>
											</div>
										</div>
									}

									{
										props.about &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												{
													generalText.profileItem?.about?.headline &&
													<div className="SECTION__subtitle margin-top-sm text-left font-xs">
														<span className={generalText.profileItem?.about?.icon}></span> <strong>{ generalText.profileItem?.about?.headline }</strong>
													</div>
												}

												<div className="PROFILEITEM__text margin-top-xs">
													<div className={'text' + 
														(isAboutExpandable ? ' expandable' : '') +
														(props.onBeforeSendMessage === undefined ? ' fixed-height' : '')
													}>
														<div ref={aboutRef}>
															<p>
																{ 
																	(props.onBeforeSendMessage === undefined && props.about.length > 400) ? 
																	props.about.substring(0, 400) + ' ...' : 
																	props.about
																}
															</p>
														</div>
													</div>

													{
														isAboutExpandable &&
														props.slug &&
														generalText.profileItem?.readMoreText &&
														<div className="PROFILEITEM__text-readmore">
															<Link to={slugByPageId('profile', lang, routes).replace(':slug', props.slug)} className="LINK">{ generalText.profileItem?.readMoreText }</Link>
														</div>
													}
												</div>
											</div>
										</div>
									}

									{
										props.reason &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												{
													generalText.profileItem?.reason?.headline &&
													<div className="SECTION__subtitle margin-top-sm text-left font-xs">
														<span className={generalText.profileItem?.reason?.icon}></span> <strong>{ generalText.profileItem?.reason?.headline }</strong>
													</div>
												}

												<div className="PROFILEITEM__text margin-top-xs">
													<div className="text">
														<p>{ props.reason }</p>
													</div>
												</div>
											</div>
										</div>
									}

									{
										props.interests &&
										props.interests?.length > 0 &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												{
													generalText.profileItem?.interests?.headline &&
													<div className="SECTION__subtitle margin-top-sm text-left font-xs">
														<span className={generalText.profileItem?.interests?.icon}></span> <strong>{ generalText.profileItem?.interests?.headline }</strong>
													</div>
												}

												<div className="PROFILEITEM__text margin-top-xs">
													<div className={'PROFILEITEM__text-textbubbles' + (props.onBeforeSendMessage === undefined ? ' fixed-height' : '')}>
														{
															_.map(props.interests, (val1, i1) => {
																return (
																	<span key={i1} className="TEXTBUBBLE">{ val1 }</span>
																)
															})
														}
													</div>
												</div>
											</div>
										</div>
									}

									{
										props.skills &&
										props.skills?.length > 0 &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												{
													generalText.profileItem?.skills?.headline &&
													<div className="SECTION__subtitle margin-top-sm text-left font-xs">
														<span className={generalText.profileItem?.skills?.icon}></span> <strong>{ generalText.profileItem?.skills?.headline }</strong>
													</div>
												}

												<div className="PROFILEITEM__text margin-top-xs">
													<div className="PROFILEITEM__text-textbubbles">
														{
															_.map(props.skills, (val1, i1) => {
																return (
																	<span key={i1} className="TEXTBUBBLE">{ val1 }</span>
																)
															})
														}
													</div>
												</div>
											</div>
										</div>
									}

									{
										props.networks && 
										props.networks?.length > 0 &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												{
													generalText.profileItem?.networks?.headline &&
													<div className="SECTION__subtitle margin-top-sm text-left font-xs">
														<span className={generalText.profileItem?.networks?.icon}></span> <strong>{ generalText.profileItem?.networks?.headline }</strong>
													</div>
												}

												<div className="margin-top-xs">
													<div className={'PROFILEITEM__networks' + (props.onBeforeSendMessage !== undefined ? ' diff1 row' : '')}>
														{
															_.map(props.networks, (val1: any, i1: number) => {
																// if (props.onBeforeSendMessage === undefined && i1 > 2) { return; }

																let urlClicks = _.find(props.actionStats?.clicks, {'_id': val1.url});
																
																return(
																	<div key={i1} className={props.onBeforeSendMessage !== undefined ? ' col-6 col-sm-4' : ''}>
																		<div onClick={() => props.clickedUrlCheck({'slug': props.slug, 'url': val1.url})} className={'PROFILEITEM__networks-item bg-' + val1.network.toLowerCase()}>
																			{
																				(val1.network.toLowerCase() === 'website') &&
																				<span className="icon-globe-regular"></span>
																			}

																			{
																				(val1.network.toLowerCase() !== 'website') &&
																				<span className={'icon-' + val1.network.toLowerCase() }></span>
																			}

																			{
																				val1.network &&
																				props.onBeforeSendMessage !== undefined &&
																				<span className="PROFILEITEM__networks-item-title">
																					{ val1.network }
																				</span>
																			}

																			{
																				props.userId === auth.id &&
																				props.onBeforeSendMessage !== undefined &&
																				<span className="PROFILEITEM__networks-item-count">
																					{ urlClicks?.count ? urlClicks.count : '0' } clicks
																				</span>
																			}
																		</div>
																	</div>
																)
															})
														}
													</div>
												</div>
											</div>
										</div>
									}

									{
										auth.isAuthenticated &&
										props.isContactable &&
										props.userId !== auth.id &&
										props.onBeforeSendMessage !== undefined &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												<div className="row margin-top-md">
													<div className="col-12">
														<div className="BUTTON diff2 no-top-margin" onClick={() => props.onBeforeSendMessage(props.profileId)}>
															<span className={generalText.profileItem?.button5?.icon}></span> { generalText.profileItem?.button5?.text }
														</div>
													</div>

													{
														generalText.reportLink?.text &&
														<div className="col-6">
															<div className="BUTTON diff1" onClick={() => props.profileToReportId(props.profileId)}>
																{ generalText.reportLink?.text }
															</div>
														</div>
													}

													{
														!props.blocked && 
														generalText.blockLink?.text &&
														<div className="col-6">
															<div className="BUTTON diff1" onClick={() => props.profileToBlockUnblockId(props.profileId, 'profileToBlockId')}>
																{ generalText.blockLink?.text }
															</div>
														</div>
													}

													{
														props.blocked && 
														generalText.blockLink?.undoText &&
														<div className="col-6">
															<div className="BUTTON diff1" onClick={() => props.profileToBlockUnblockId(props.profileId, 'profileToUnblockId')}>
																{ generalText.blockLink?.undoText }
															</div>
														</div>
													}
												</div>
											</div>
										</div>
									}

									{
										(
											props.category ||
											props.types
										) &&
										<div className="col-12">
											<div className="PROFILEITEM__section">
												<div className="PROFILEITEM__footer margin-top-xs">
													{
														props.category &&
														<div className="PROFILEITEM__footer-textbubbles text-left font-weight-md text-transform-uppercase">
															<span className="TEXTBUBBLE no-bg">
																{ props.category }
															</span>
														</div>
													}

													{
														props.types &&
														<div className="PROFILEITEM__footer-textbubbles">
															{
																_.map(props.types, (val1, i1) => {
																	return (
																		<span key={i1} className={'TEXTBUBBLE has-bg ' + val1.toLowerCase()}>{ val1 }</span>
																	)
																})
															}
														</div>
													}
												</div>
											</div>
										</div>
									}
								</div>
							</div>
						}
					</>
				}

				{
					!props.isVisible &&
					props.userId !== auth.id &&
					<>
						<div className="PROFILEIMAGE diff1">
							<div className="PROFILEIMAGE__inner">
								<div className={generalText.profileItem?.hiddenProfile?.icon}></div>
							</div>
						</div>
						
						<div className="grid">
							<div className="row">
								<div className="col-12">
									<div className="PROFILEITEM__section no-padding">
										{
											props.layout !== 'xs' &&
											generalText.profileItem?.hiddenProfile?.title &&
											<div className="PROFILEITEM__name">
												{ generalText.profileItem?.hiddenProfile?.title }
											</div>
										}

										{
											props.layout !== 'xs' &&
											generalText.profileItem?.hiddenProfile?.text &&
											<div dangerouslySetInnerHTML={{ __html: generalText.profileItem?.hiddenProfile?.text }}></div>
										}
									</div>
								</div>

								{
									props.layout !== 'xs' &&
									props.favoriteToRemoveId !== undefined && 
									generalText.profileItem?.button1?.icon &&
									<div className="col-12">
										<div className="PROFILEITEM__section text-center">
											<div className="PROFILEITEM__buttons margin-top-sm">
												<div className="PROFILEITEM__buttons-inner">
													<div onClick={() => props.favoriteToRemoveId(props.profileId)} className="PROFILEITEM__buttons-item remove">
														<div className={generalText.profileItem?.button1?.icon}></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</>
				}
			</div>
		</div>
	);
}

export default ProfileItem;
