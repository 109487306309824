import React, { useState, useEffect } from 'react';
import './typing-effect.scss';

interface Props {
	texts: string[];
	typingSpeed: number;
	switchDelay: number;
	typingMode: 'word' | 'character';
}

const TypingEffect: React.FC<Props> = (props) => {
	const [currentTitleIndex, setCurrentTitleIndex] = useState<number>(0);
	const [displayedTitle, setDisplayedTitle] = useState<string>('');
	const [currentIndex, setCurrentIndex] = useState<number>(0);

	useEffect(() => {
		let typingInterval: NodeJS.Timeout;
		let switchInterval: NodeJS.Timeout;
	
		const splitWords = (text: string): string[] => {
			const regex = /<span>.*?<\/span>|\S+/g;
			return text.match(regex) || [];
		};
	
		const typeCharacter = () => {
			typingInterval = setInterval(() => {
				const currentText = props.texts[currentTitleIndex];

				if (currentIndex < currentText.length) {
					setDisplayedTitle((prev) => prev + currentText[currentIndex]);
					setCurrentIndex((prev) => prev + 1);
				} else {
					clearInterval(typingInterval);
				}
			}, props.typingSpeed);
		};
	
		const typeWord = () => {
			typingInterval = setInterval(() => {
				const currentText = props.texts[currentTitleIndex];
				const words = splitWords(currentText);

				if (currentIndex < words.length) {
					setDisplayedTitle((prev) => prev + (currentIndex > 0 ? ' ' : '') + `<div class="word">${words[currentIndex]}</div>`);
					setCurrentIndex((prev) => prev + 1);
				} else {
					clearInterval(typingInterval);
				}
			}, props.typingSpeed);
		};
	
		if (props.typingMode === 'word') {
			typeWord();
		} else {
			typeCharacter();
		}
	
		switchInterval = setInterval(() => {
			setDisplayedTitle('');
			setCurrentIndex(0);
			setCurrentTitleIndex((prevIndex) => (prevIndex + 1) % props.texts.length);
		}, props.switchDelay + (props.typingMode === 'word' ? splitWords(props.texts[currentTitleIndex]).length * props.typingSpeed : props.texts[currentTitleIndex].length * props.typingSpeed));

		return () => {
			clearInterval(typingInterval);
			clearInterval(switchInterval);
		};
	}, [currentTitleIndex, currentIndex, props.texts, props.typingSpeed, props.switchDelay, props.typingMode]);

	return (
		<div className="typing-container" dangerouslySetInnerHTML={{ __html: displayedTitle }}></div>
	);
};

export default TypingEffect;
