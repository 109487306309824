import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Controller } from 'react-scrollmagic';
import parse from 'html-react-parser';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import actions
import { setCurrentUser } from './../../other/actions/auth';

// Import helpers
import { validateFields } from './../../other/helpers/validate-fields';
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
import Textarea from './../../components/textarea/textarea';
// import PageHeader from './../../components/page-header/page-header';
import ProfileItem from './../../components/profile-item/profile-item';
import PostItem from './../../components/post-item/post-item';

// Import styles
import './profile.scss';

interface props {
	'pageId': string
}

const Profile = (props: props) => {
	const { slug }: any = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	// const initData: {[key: string]: any} = {'interests': []};
	const initMessage: {[key: string]: any} = {
		'message': ''
	};
	const initReport: {[key: string]: any} = {'report': ''};
	const initPopups: {[key: string]: any} = {
		'viewOfferId': '',
		'sendMessageProfileId': '',
		'profileToReportId': '',
		'profileToBlockId': '',
		'profileToUnblockId': '',
		'postToReportId': '',
		'postToRemoveId': ''
	};
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [profile, setProfile] = useState<{[key: string]: any}>({});
	const [actionStats, setActionStats] = useState<{[key: string]: any}>({});
	const [clickedUrlCheck, setClickedUrlCheck] = useState<{[key: string]: any}>({});
	const [posts, setPosts] = useState<Array<any>>([]);
	const [postsPage, setPostsPage] = useState<number>(1);
	const [postsTotal, setPostsTotal] = useState<number>(1);
	const [postsFromDate, setPostsFromDate] = useState<string>('');
	// const [data, setData] = useState<{[key: string]: any}>(initData);
	const [message, setMessage] = useState<{[key: string]: any}>(initMessage);
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [report, setReport] = useState<{[key: string]: any}>(initReport);
	const [errors, setErrors] = useState<{[key: string]: any}>({});
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			onGetProfile(slug);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const onGetProfile: any = useCallback((slug: string) => {
		let apiUrl: string = '/get-public-profile/' + slug;

		if (auth.isAuthenticated) {
			apiUrl = '/get-profile/' + slug;
		}

		setIsLoading(true);

		api.get(apiUrl).then((res) => {
			if (!res.data[0]) {
				navigate(slugByPageId('not-found', lang, routes), {'replace': true});
			}

			setProfile(res.data[0]);
			onAddActionStats(slug, {'type': 'view'});
			setIsLoading(false);
			
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});
		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);


	useEffect(() => {
		onGetPosts(1);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	const onGetPosts: any = (page: number) => {
		if (!profile._id) { return; }

		setIsLoading(true);

		// Reinit state if this is the first page
		if (page === 1) {
			setPosts([]);
			setPostsPage(1);
			setPostsTotal(1);
			setPostsFromDate(new Date().toString());
		}

		api.get('/get-profile-posts/' + profile._id + '/' + (postsFromDate ? postsFromDate : 'null') +  '/' + page).then((res) => {
			// Push new posts into cloned state
			setIsLoading(false);
			setPostsPage(page + 1);
			setPosts(prevState => ([...prevState, ...res.data.entries]));

			// Get count of total posts and first post's date from DB
			if (page === 1) {
				setPostsTotal(res.data?.total || 0);
			}

		}).catch((err) => {

			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});

	};

	useEffect(() => {
		if (!slug) {
			navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!auth.isAuthenticated || profile.userId !== auth.id) { return; }

		api.get('/get-action-stats/' + profile.slug).then((res) => {
			setActionStats(res.data);

		}).catch((err) => {
			setNotifications(err.response?.data?.messages);
		});
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [profile]);

	const onAddActionStats: any = useCallback((slug: string, data: {[key: string]: any}) => {
		let actionStatsData: object = {
			'slug': slug,
			...data
		}

		api.post('add-action', actionStatsData);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onToggleFavorite = async (receiverProfileId: string, slug: string) => {
		if (!auth.isAuthenticated || !receiverProfileId || !slug) {
			return;
		}

		// Check if user has selected his main profile
		if (!auth.mainProfile?.slug) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.profileIsNotSelected);

			return;
		}

		let favoriteData: any = {
			'receiverProfileId': receiverProfileId
		}

		await new Promise((resolve, reject) => {
			api.post('/toggle-favorite', favoriteData).then((res1) => {
				resolve(true);

			}).catch((err) => {
				setNotifications(err.response?.data?.messages);

				reject(false);
			});
		});

		onGetProfile(slug);
	}

	const onViewOffer = (profileId: string) => {
		if (!auth.isAuthenticated) { return; }

		// Check if user has enough credits
		if (auth.credits < generalText.keys?.viewOfferPrice) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.notEnoughCreditsError);

			return;
		}

		setIsLoading(true);

		api.get('/view-profile-offer/' + profileId).then((res1) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications({
				'title': 'Offer details',
				'text': [profile.offer]
			});

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onBeforeSendMessage = (receiverProfileId: string) => {
		if (!auth.isAuthenticated) { return; }

		// Check if profile accepts new messages
		if (!profile.isContactable) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.receiverDoesNotAcceptMessagesError);

			return;
		}

		// Check if user has selected his main profile
		if (!auth.mainProfile?.slug) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.profileIsNotSelected);

			return;
		}

		// Check if user has enough credits
		if (auth.credits < generalText.keys?.sendMessagePrice) {
			setPopups(initPopups);
			setNotifications(generalText.messages?.notEnoughCreditsError);

			return;
		}

		if (auth.mainProfile?.status !== 1) {
			setNotifications(generalText.messages?.profileNotConfirmedMessage);

			return;
		}

		setPopups(prevState => ({...initPopups, 'sendMessageProfileId': receiverProfileId}));
	}

	const onSendMessage = (receiverProfileId: string) => {
		setIsLoading(true);

		let stateClone: any = _.cloneDeep(message);

		// Validation
		let fieldErrors = validateFields(stateClone, ['conversation']);

		if (!_.isEmpty(fieldErrors)) {
			setIsLoading(false);
			setErrors(fieldErrors);

			return;
		}

		let messageData = {
			'senderProfileSlug': auth.mainProfile?.slug,
			'receiverProfileId': receiverProfileId,
			'message': message.message
		}

		api.post('/add-message', messageData).then((res1) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(generalText.messages?.messageSentMessage);

			dispatch(setCurrentUser({...auth, 'toggleUpdate': !auth.toggleUpdate}));

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onToggleProfileBlock = () => {
		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		if (!popups.profileToBlockId && !popups.profileToUnblockId) {
			return;
		}

		setIsLoading(true);

		let blockData: any = {
			'profileId': popups.profileToBlockId ? popups.profileToBlockId : popups.profileToUnblockId
		}

		// Block profile
		api.post('/toggle-block', blockData).then((res) => {

			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(res.data.messages);

			if (!slug) {
				navigate(slugByPageId('not-found', lang, routes), {'replace': true});

				return;
			}

			onGetProfile(slug);

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onPostRemove = () => {
		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		setIsLoading(true);

		let postData: any = {
			'id': popups.postToRemoveId
		}
		
		// Remove post
		api.put('/remove-post', postData).then((res) => {
			setPopups(initPopups);

			onGetPosts(1);

		}).catch((err) => {
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);

			setIsLoading(false);
		});
	}

	const onReport = (type: string) => {
		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}

		setIsLoading(true);

		let stateClone: any = _.cloneDeep(report);

		// Validation
		let fieldErrors = validateFields(stateClone, ['report']);

		if (!_.isEmpty(fieldErrors)) {
			setErrors(fieldErrors);
			setIsLoading(false);

			return;
		}

		let reportData: any = {
			'report': report.report
		}

		if (type === 'profile') {
			reportData['id'] = popups.profileToReportId;
			reportData['type'] = 'Profile';
		}

		if (type === 'post') {
			reportData['id'] = popups.postToReportId;
			reportData['type'] = 'Post';
		}

		// Report conversation
		api.post('/add-report', reportData).then((res) => {
			
			setPopups(initPopups);
			setReport(initReport);
			setNotifications(generalText.messages?.itemReportedMessage);
			setIsLoading(false);

		}).catch((err) => {

			setPopups(initPopups);
			setReport(initReport);
			setNotifications(err.response?.data?.messages);
			setIsLoading(false);
		
		});
	}

	const onToggleLikeDislike = async (postId: string, isLiked: boolean) => {
		if (!auth.isAuthenticated) {
			setNotifications(generalText.messages?.userNotAuthorizedError);
			return;
		}
		
		// Check if user has selected his main profile
		if (!auth.mainProfile?.slug) {
			setNotifications(generalText.messages?.profileIsNotSelected);

			return;
		}

		let likeData: any = {
			'isLiked': isLiked,
			'postId': postId,
			'profileSlug': auth.mainProfile?.slug
		}

		// Add or remove like / dislike 
		await new Promise((resolve, reject) => {
			api.post('/toggle-like-dislike', likeData).then((res1) => {
				resolve(true);
				
			}).catch((err) => {
				setNotifications(err.response?.data?.messages);
				reject(false);
			});
		});

		getPost(postId);
	}

	const getPost = (postId: string) => {
		api.get('/get-post/' + postId).then((res1) => {
				
			// Replaced post with an updated version
			onPostUpdate(res1.data[0]);
			setIsLoading(false);

		}).catch((err) => {
			setIsLoading(false);

			setNotifications(err.response?.data?.messages);
		});
	}

	const onPostUpdate = (updatedPost: {[key: string]: any}) => {
		// Replaced post with an updated version
		let stateClone = _.cloneDeep(posts);
		let index = _.findIndex(stateClone, {'_id': updatedPost._id});

		stateClone[index] = updatedPost;

		setPosts(stateClone);
	}

	const onOpenExternalLink = () => {
		onAddActionStats(slug, {'type': 'click', 'value': clickedUrlCheck.url});

		let url = clickedUrlCheck.url;

		if (!url.match(/^https?:\/\//i)) {
			url = 'https://' + url;
		}

		setClickedUrlCheck({});

		window.open(url, '_blank');
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="PROFILE">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle.replace('%repStr1%', profile.name) }</title>
				<meta name="description" content={text.pageDescription.replace('%repStr1%', profile.name).replace('%repStr2%', profile.about)} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Popup
				title={generalText.externalLinkPopup?.title}
				closeBtn={generalText?.generalPopup?.button1?.text}
				submitBtn={generalText.externalLinkPopup?.button1?.text}
				active={!_.isEmpty(clickedUrlCheck)}
				onSubmit={() => onOpenExternalLink()}
				onClose={() => setClickedUrlCheck({})}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								generalText.externalLinkPopup?.text1 &&
								<div className="col-12">
									{
										parse(generalText.externalLinkPopup?.text1, {
											replace: (domNode: any) => {
												if (domNode.attribs?.class === '%repStr1%') {
													return <strong>{ clickedUrlCheck.url }</strong>;
												}
											}
										})
									}
								</div>
							}
						</div>
					</div>
				</div>
			</Popup>

			{
				auth.isAuthenticated &&
				<Popup
					title={text.blockProfilePopup?.title}
					text={text.blockProfilePopup?.text1}
					submitBtn={text.blockProfilePopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={popups.profileToBlockId !== ''}
					onSubmit={() => onToggleProfileBlock()}
					onClose={() => setPopups(initPopups)}
				/>
			}

			{
				auth.isAuthenticated &&
				<Popup
					title={text.unblockProfilePopup?.title}
					text={text.unblockProfilePopup?.text1}
					submitBtn={text.unblockProfilePopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={popups.profileToUnblockId !== ''}
					onSubmit={() => onToggleProfileBlock()}
					onClose={() => setPopups(initPopups)}
				/>
			}

			{
				auth.isAuthenticated &&
				<Popup
					title={text.reportProfilePopup?.title}
					submitBtn={text.reportProfilePopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={popups.profileToReportId !== ''}
					onSubmit={() => onReport('profile')}
					onClose={() => setPopups(initPopups)}
				>
					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									<div className="SECTION__item">
										<div className={'FIELD' + (errors.report ? ' error' : '')}>
											<Textarea
												value={report.report}
												placeholder={text.reportProfilePopup?.input1?.placeholder}
												label={text.reportProfilePopup?.input1?.label}
												onChange={(val: string) => setReport({...report, 'report': val})}
											/>

											{
												errors['report'] &&
												<p className="error">
													{ errors.report }
												</p>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Popup>
			}

			{
				auth.isAuthenticated &&
				<Popup
					title={text.startConversationPopup?.title}
					submitBtn={text.startConversationPopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={popups.sendMessageProfileId !== ''}
					onClose={() => setPopups(initPopups)}
					onSubmit={() => onSendMessage(profile._id)}
				>
					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								{
									auth.mainProfile?.name && text.startConversationPopup?.text1 &&
									<div className="col-12">
										{
											parse(text.startConversationPopup?.text1, {
												replace: (domNode: any) => {
													if (domNode.attribs?.class === '%repStr1%') {
														return <Link to={slugByPageId('user-profiles', lang, routes)} className="LINK">{ auth.mainProfile?.name }</Link>;
													}
												}
											})
										}
									</div>
								}

								{
									!auth.mainProfile?.name && text.startConversationPopup?.text2 &&
									<div className="col-12" dangerouslySetInnerHTML={{ __html: text.startConversationPopup?.text2 }}></div>
								}

								<div className="col-12">
									<div className={'FIELD' + (errors.message ? ' error' : '')}>
										<Textarea
											value={message.message}
											label={text.startConversationPopup?.textarea1?.label}
											placeholder={text.startConversationPopup?.textarea1?.placeholder}
											onChange={(val: string) => setMessage(prevState => ({...prevState, 'message': val}))}
											minCharLength={2}
											maxCharLength={10000}
										/>

										{
											errors['message'] &&
											<p className="error">
												{ errors.message }
											</p>
										}
									</div>
								</div>

								{
									text.startConversationPopup?.text3 &&
									<div className="col-12">
										{
											parse(text.startConversationPopup?.text3, {
												replace: (domNode: any) => {
													if (domNode.attribs?.class === '%repStr1%') {
														return <span>{ generalText.keys?.sendMessagePrice }</span>;
													}
												}
											})
										}
									</div>
								}
							</div>
						</div>
					</div>
				</Popup>
			}

			{
				auth.isAuthenticated &&
				<Popup
					title={text.viewDiscountOfferPopup?.title}
					submitBtn={text.viewDiscountOfferPopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={!_.isEmpty(popups.viewOfferId)}
					onClose={() => setPopups(initPopups)}
					onSubmit={() => onViewOffer(popups.viewOfferId)}
				>
					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								{
									text.viewDiscountOfferPopup?.text1 &&
									<div className="col-12" dangerouslySetInnerHTML={{ __html: text.viewDiscountOfferPopup?.text1 }}></div>
								}

								{
									text.viewDiscountOfferPopup?.text2 &&
									<div className="col-12">
										{
											parse(text.viewDiscountOfferPopup?.text2, {
												replace: (domNode: any) => {
													if (domNode.attribs?.class === '%repStr1%') {
														return <span>{ generalText.keys?.viewOfferPrice }</span>;
													}
												}
											})
										}
									</div>
								}
							</div>
						</div>
					</div>
				</Popup>
			}

			{
				auth.isAuthenticated &&
				<Popup
					title={text.reportPostPopup?.title}
					submitBtn={text.reportPostPopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={popups.postToReportId !== ''}
					onSubmit={() => onReport('post')}
					onClose={() => setPopups(initPopups)}
				>
					<div className="SECTION no-bottom-padding">
						<div className="grid">
							<div className="row">
								<div className="col-12">
									<div className="SECTION__item">
										<div className={'FIELD' + (errors.report ? ' error' : '')}>
											<Textarea
												value={report.report}
												placeholder={text.reportPostPopup?.input1?.placeholder}
												label={text.reportPostPopup?.input1?.label}
												onChange={(val: string) => setReport(prevState => ({...prevState, 'report': val}))}
											/>

											{
												errors.report &&
												<p className="error">
													{ errors.report }
												</p>
											}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Popup>
			}

			{
				auth.isAuthenticated &&
				<Popup
					title={text.removePostPopup?.title}
					text={text.removePostPopup?.text1}
					submitBtn={text.removePostPopup?.button1?.text}
					closeBtn={generalText?.generalPopup?.button1?.text}
					active={popups.postToRemoveId !== ''}
					onSubmit={() => onPostRemove()}
					onClose={() => setPopups(initPopups)}
				/>
			}

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #573563452452']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>
				
			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				{/*<PageHeader heading={text.profile?.title} />*/}

				{
					!_.isEmpty(profile) &&
					<div className="SECTION">
						<div className="grid">
							<div className="row">
								<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
									<div className="SECTION__item">
										<ProfileItem
											isVisible={profile.isVisible}
											isContactable={profile.isContactable}
											userId={profile.userId}
											profileId={profile._id}
											name={profile.name}
											slug={profile.slug}
											covers={profile.covers}
											images={profile.images}
											types={profile.types}
											category={profile.category}
											country={profile.country}
											city={profile.city}
											about={profile.about}
											reason={profile.reason?.[0]?.value}
											interests={profile.interests}
											skills={profile.skills}
											offer={profile.offer}
											networks={profile.networks}
											raisedAt={profile.raisedAt}
											valueRating={profile.valueRating}
											isUnclaimed={profile.isUnclaimed}
											blocked={profile.blocked}
											favorited={profile.favorited}
											activeAt={profile.activeAt}
											actionStats={actionStats}
											clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
											setNotifications={(val: {[key: string]: any}) => setNotifications(val)}
											profileToReportId={(val: string) => setPopups({...initPopups, 'profileToReportId': val})}
											profileToBlockUnblockId={(val: string, blockType: string) => setPopups({...initPopups, [blockType]: val})}
											onBeforeSendMessage={(val: string) => onBeforeSendMessage(val)}
											onToggleFavorite={(id: string, slug: string) => onToggleFavorite(id, slug)}
											viewOfferId={(val: string) => setPopups({...initPopups, 'viewOfferId': val})}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				}

				{
					posts?.length > 0 &&
					<div className="PROFILE__feed">
						<div className="SECTION">
							<div className="grid">
								<div className="row">
									<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
										<div className="row">
											{
												text.profilePosts?.title &&
												<div className="col-12">
													<div className="SECTION__title font-lg" dangerouslySetInnerHTML={{ __html: text.profilePosts?.title.replace('%repStr1%', profile.name) }}></div>
												</div>
											}

											{
												_.map(posts, (val1, i1) => {
													return (
														<div key={i1} className="col-12">
															<div className="SECTION__item">
																<PostItem
																	postId={val1._id}
																	userId={val1.userId}
																	profileId={val1.profileId}
																	isProfileVisible={val1.profile?.isVisible}
																	profileStatus={val1.profile?.status}
																	profileSlug={val1.profile?.slug}
																	name={val1.profile?.name}
																	images={val1.profile?.images}
																	profileNetworks={val1.profile?.networks}
																	text={val1.text?.[lang]}
																	country={val1.country}
																	city={val1.city}
																	type={val1.type}
																	category={val1.category}
																	interest={val1.interest}
																	likes={val1.likes}
																	dislikes={val1.dislikes}
																	isLiked={val1.isLiked}
																	isDisliked={val1.isDisliked}
																	comments={val1.comments}
																	createdAt={val1.createdAt}
																	status={val1.status}
																	statusMessage={val1.statusMessage}
																	kind={val1.kind}
																	slug={val1.slug?.[lang]}
																	clickedUrlCheck={(val: {[key: string]: any}) => setClickedUrlCheck(val)}
																	postToReportId={(val: string) => setPopups({...initPopups, 'postToReportId': val})}
																	postToRemoveId={(val: string) => setPopups({...initPopups, 'postToRemoveId': val})}
																	onViewStatusMessage={(val: string) => setNotifications({'title': text.profilePosts?.rejectionTitle, 'text': [val]})}
																	onToggleLikeDislike={(postId: string, isLiked: boolean) => onToggleLikeDislike(postId, isLiked)}
																/>
															</div>
														</div>
													)
												})
											}

											{
												(posts.length > 0 && postsTotal > posts.length) &&
												<div className="col-6 push-3">
													<div className="SECTION__item">
														<button onClick={() => onGetPosts(postsPage)} className="BUTTON">{ text.profilePosts?.footer?.button1?.text }</button>
													</div>
												</div>
											}
										</div>
									</div>
								</div>
							</div>
						</div>

					</div>
				}
			</Controller>
		</div>
	);
}

export default Profile;