import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Controller } from 'react-scrollmagic';
import _ from 'lodash';

// Import middlewares
import api from './../../other/middlewares/api';

// Import helpers
import { slugByPageId } from './../../other/helpers/route-finder';

// Import components
import Loader from './../../components/loader/loader';
import Popup from './../../components/popup/popup';
import Guide from './../../components/guide/guide';
import PageHeader from './../../components/page-header/page-header';
import ProfileItem from './../../components/profile-item/profile-item';

// Import styles
import './../user-profiles/user-profiles.scss';

interface props {
	'pageId': string
}

const UserProfiles = (props: props) => {
	const navigate = useNavigate();

	const auth = useSelector((state: {[key: string]: any}) => state.auth);
	const lang = useSelector((state: {[key: string]: any}) => state.lang);
	const routes = useSelector((state: {[key: string]: any}) => state.routes);
	const generalText = useSelector((state: {[key: string]: any}) => state.generalText);

	const initPopups: {[key: string]: any} = {
		'favoriteToRemoveId': '',
	}
	const initNotifications: {[key: string]: any} = {'title': '', 'text': []};

	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [text, setText] = useState<{[key: string]: any}>({});
	const [favorites, setFavorites] = useState<Array<any>>([]);
	const [favoritesPage, setFavoritesPage] = useState<number>(1);
	const [favoritesTotal, setFavoritesTotal] = useState<number>(1);
	const [popups, setPopups] = useState<{[key: string]: any}>(initPopups);
	const [notifications, setNotifications] = useState<{[key: string]: any}>(initNotifications);

	useEffect(() => {
		api.get('/get-page/' + props.pageId).then((res) => {
			setText(res.data.text[lang]);
			getFavorites(1);
		}).catch((err) => {
			return navigate(slugByPageId('not-found', lang, routes), {'replace': true});
		});

		return () => {
			setText({});
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang, props.pageId]);

	const getFavorites: any = (page: number) => {
		setIsLoading(true);

		if (page === 1) {
			setFavorites([]);
			setFavoritesPage(1);
			setFavoritesTotal(1);
		}

		// Get favorites
		api.get('/get-favorites/' + page).then((res) => {	
			// Push new favorites into cloned state
			setIsLoading(false);
			setFavoritesPage(page + 1);
			setFavorites(favorites => ([...favorites, ...res.data.entries]));

			// Add total favorites count from DB
			if (page === 1 && res.data?.total) {
				setFavoritesTotal(res.data.total);
			}

		}).catch((err) => {
			setIsLoading(false);
			setNotifications(err.response?.data?.messages);
		});
	}

	useEffect(() => {
		// Check if the user email is unconfirmed
		if (auth.profilesCount === 0 && !auth.isEmailConfirmed) {
			setNotifications(generalText.messages?.emailNotConfirmedMessage);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onToggleFavorite = () => {
		setIsLoading(true);

		let favoriteData: any = {
			'receiverProfileId': popups.favoriteToRemoveId
		}

		// Add or remove favorite profile
		api.post('/toggle-favorite', favoriteData).then((res) => {
			setIsLoading(false);
			setPopups(initPopups);
			onFavoriteUpdate(popups.favoriteToRemoveId);

		}).catch((err) => {
			setIsLoading(false);
			setPopups(initPopups);
			setNotifications(err.response?.data?.messages);
		});
	}

	const onFavoriteUpdate = (updatedItemId: {[key: string]: any}) => {
		// Replaced favorite
		let stateClone = _.cloneDeep(favorites);
		let index = _.findIndex(stateClone, {'receiverProfileId': updatedItemId});

		stateClone.splice(index, 1);

		setFavorites(stateClone);
		setFavoritesTotal(stateClone.length - 1);
	}

	if (_.isEmpty(text)) { return null; }

	return (
		<div className="USERPROFILES full-height">
			<Helmet
				titleTemplate={generalText.siteTitle + ' | %s'}
				defaultTitle={generalText.siteTitle}
			>
				<html lang={lang} />
				<title>{ text.pageTitle }</title>
				<meta name="description" content={text.pageDescription} />
			</Helmet>
			
			<Loader active={isLoading} fixed={true} />

			<Guide
				title={text.guidePopup?.title}
				text={text.guidePopup?.text1}
				nextButton={text.guidePopup?.button1}
			/>

			<Popup
				title={text.removeFavoritePopup?.title}
				submitBtn={text.removeFavoritePopup?.button1?.text}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={popups.favoriteToRemoveId !== ''}
				onSubmit={() => onToggleFavorite()}
				onClose={() => setPopups(initPopups)}
			>
				<div className="SECTION no-bottom-padding">
					<div className="grid">
						<div className="row">
							{
								text.removeFavoritePopup?.text1 &&
								<div className="col-12" dangerouslySetInnerHTML={{ __html: text.removeFavoritePopup?.text1 }}></div>
							}
						</div>
					</div>
				</div>
			</Popup>

			<Popup
				title={notifications?.title || 'Something went wrong'}
				text={notifications?.text || ['Unhandled error - #1341352242423']}
				closeBtn={generalText?.generalPopup?.button1?.text}
				active={!_.isEmpty(notifications?.text)}
				onClose={() => setNotifications(initNotifications)}
			/>

			<Controller globalSceneOptions={{
				'triggerHook': 0.95
			}}>
				{
					favorites?.length > 0 &&
					<PageHeader heading={text.pageTitle.replace('%repStr1%', (auth.mainProfile?.name || 'Your'))} />
				}

				{
					favorites?.length > 0 &&
					<div className="SECTION has-top-border">
						<div className="grid">
							<div className="row">
								{
									_.map(favorites, (val1, i1) => {
										if (
											!val1.profile?.isVisible || 
											val1.profile?.status !== 1
										) {
											return (
												<div key={i1} className="col-12 col-sm-6 col-md-4">
													<div className="SECTION__item">
														<ProfileItem
															userId={val1.receiverUserId}
															profileId={val1.receiverProfileId}
															isFavorite={true}
															isVisible={false}
															types={val1.profile.types}
															category={val1.profile.category}
															favoriteToRemoveId={(val: string) => setPopups({...initPopups, 'favoriteToRemoveId': val})}
														/>
													</div>
												</div>
											)
										}

										return(
											<div key={i1} className="col-12 col-sm-6 col-md-4">
												<div className="SECTION__item">
													<ProfileItem
														userId={val1.receiverUserId}
														profileId={val1.receiverProfileId}
														isFavorite={true}
														isVisible={val1.profile.isVisible}
														isContactable={val1.profile.isContactable}
														name={val1.profile.name}
														slug={val1.profile.slug}
														covers={val1.profile.covers}
														images={val1.profile.images}
														country={val1.profile.country}
														city={val1.profile.city}
														types={val1.profile.types}
														category={val1.profile.category}
														offer={val1.profile.offer}
														valueRating={val1.profile.valueRating}
														isUnclaimed={val1.profile.isUnclaimed}
														activeAt={val1.profile.activeAt}
														favoriteToRemoveId={(val: string) => setPopups({...initPopups, 'favoriteToRemoveId': val})}
													/>
												</div>
											</div>
										)
									})
								}

								{
									favorites?.length > 0 && 
									(favoritesTotal > favorites.length) && 
									text.favoriteProfiles?.footer?.button1?.text &&
									<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
										<div className="SECTION__item">
											<div className="row">
												<div className="col-6 push-3">
													<button onClick={() => getFavorites(favoritesPage)} className="BUTTON">{ text.favoriteProfiles?.footer?.button1?.text }</button>		
												</div>
											</div>
										</div>
									</div>
								}
								
								{
									(favoritesTotal <= favorites.length) && 
									text.favoriteProfiles?.footer?.button1?.disabledText &&
									<div className="col-12 col-sm-8 push-sm-2 col-md-6 push-md-3">
										<div className="SECTION__item">
											<div className="row">
												<div className="col-6 push-3">
													<button className="BUTTON diff1" disabled={true}>{ text.favoriteProfiles?.footer?.button1?.disabledText }</button>
												</div>
											</div>
										</div>
									</div>
								}
							</div>
						</div>
					</div>
				}

				{
					(!isLoading && favorites.length <= 0) &&
					<div className="SECTION text-center full-height">
						<div className="grid full-height">
							<div className="row full-height">
								<div className="col-12 full-height">
									<div className="SECTION__inner full-height no-padding">
										<div className="SECTION__inner-scroll align-content-vertical">
											<div className="SECTION__inner">
												{
													text.favoriteProfiles?.noResults?.icon &&
													<div className="SECTION__icon">
														<div className={text.favoriteProfiles?.noResults?.icon}></div>
													</div>
												}
												
												{
													text.favoriteProfiles?.noResults?.title &&
													<div className="SECTION__title">{ text.favoriteProfiles?.noResults?.title }</div>
												}
												
												{
													text.favoriteProfiles?.noResults?.subtitle &&
													<div className="SECTION__subtitle">{ text.favoriteProfiles?.noResults?.subtitle }</div>
												}
												
												{
													text.favoriteProfiles?.noResults?.text &&
													<div dangerouslySetInnerHTML={{ __html: text.favoriteProfiles?.noResults?.text }}></div>
												}

												{
													text.favoriteProfiles?.noResults?.buttons?.length > 0 &&
													<div className="col-12">
														<div className="SECTION__item">
															<div className="row">
																{
																	_.map(text.favoriteProfiles?.noResults?.buttons, (val1, i1) => {
																		return (
																			<div key={i1} className={val1.gridClasses}>	
																				<Link to={slugByPageId(val1.link, lang, routes)} className={'BUTTON' + (val1.link ? ' ' + val1.link : '')}>{ val1.text }</Link>
																			</div>
																		)
																	})
																}
															</div>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</Controller>
		</div>
	);
}

export default UserProfiles;